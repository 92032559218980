:global {
    /*footer*/
    .footer {
        background-color: #202020;
    }

    .footer__inner {
        max-width: 984px;
        margin: 0 auto;
        padding: 33px 10px 40px 10px;
    }
    @media screen and (width > $mobileBreakPoint) {
        .footer__inner {
            padding: 44px 23px 29px 23px;
        }
    }
    @media screen and (width > $tabletBreakPoint) {
        .footer__inner {
            padding: 40px 0;
        }
    }

    .footer__copyright {
        font: 14px 'opensans_regular', sans-serif;
        color: rgba(255, 255, 255, 0.24);
        text-align: center;
    }
    @media screen and (width > $smallTabletBreakPoint) {
        .footer__copyright {
            text-align: right;
        }
    }

    .footer__links {
        display: flex;
        flex-direction: column;
    }
    @media screen and (width > $smallTabletBreakPoint) {
        .footer__links {
            flex-direction: row;
        }
    }

    .footer__linksGroup {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer__linksGroup + .footer__linksGroup {
        margin-top: 33px;
    }
    .footer__links + .footer__copyright {
        margin-top: 33px;
    }
    @media screen and (width > $smallTabletBreakPoint) {
        .footer__linksGroup {
            align-items: flex-start;
        }
        .footer__linksGroup:nth-last-child(2) {
            flex-shrink: 1;
        }
        .footer__linksGroup + .footer__linksGroup {
            margin-top: 0;
            margin-left: 88px;
        }
    }
    /*footer*/
}
