:global {
    .programsPage__desc {
        padding: 0 0 40px 0;
    }
    .programsPage__shortDesc {
        margin: 0 auto 32px auto;
        font: 24px 'opensans_light';
        text-align: center;
        width: 75%;
    }
    .programsPage__previews {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .programFullPreview {
        width: 100%;
        background-color: #202020;
        border-radius: 5px;
        padding: 0 10px 20px;
    }
    .programFullPreview + .programFullPreview {
        margin-top: 10px;
    }
    .programPreview__text .htmlText {
        p + p {
            margin-top: 13px;
        }
    }

    @media screen and (width > $mobileBreakPoint) {
        .programFullPreview {
            width: 48.4%;
            padding: 12px 22px 35px 22px;
        }
        .programFullPreview + .programFullPreview {
            margin-top: 0;
        }
        .programFullPreview:first-child {
            width: 100%;
            margin-bottom: 16px;
        }
    }

    @media screen and (min-width: 600px) {
        .programFullPreview:first-child {
            margin-bottom: 20px;
        }
    }

    @media screen and (width > $tabletBreakPoint) {
        .programFullPreview,
        .programFullPreview:first-child {
            width: 31.6%;
            margin-bottom: 0;
        }
    }
}
