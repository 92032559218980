.modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;

    font: 14px 'opensans_regular';
    padding-top: 0;
    @media screen and (width > $tabletBreakPoint) {
        padding-top: 10px;
    }
}
