@value imagePath: landing/i;

:global {
    /*page styles*/
    .whiteLabelPage {

    }
    .whiteLabelPage__previewList {
        margin-top: 30px;
    }
    .whiteLabelPageSection + .whiteLabelPageSection {
        margin-top: 15px;
    }
    .whiteLabelPageSection__content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }
    .wLSectionHeader {
        background-color: #2a2a2a;
        border-radius: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 48px;
        padding: 0 20px;
    }
    .wLSectionHeader__siteName {
        font: 24px opensans_regular, sans-serif;
    }
    .wLSectionHeader__arrow {
        background-image: url('imagePath/arrow@2x.png#landing');
    }
    .wLSectionHeader__arrow_opened {
        background-image: url('imagePath/arrowOpened@2x.png#landing');
    }
    .templatePreviews + .templatePreviews {
        margin-top: 30px;
    }
    .templatePreviews__header {
        font: 14px opensans_bold, sans-serif;
        margin: 20px 0 15px 0;
    }
    .templatePreviews__gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: -20px;
    }

    @media screen and (width > $mobileBreakPoint) {
        .templatePreviews__gallery {
            justify-content: flex-start;
            margin-left: -16px;
        }
        .templatePreview {
            width: 50%;
            padding-left: 16px;
        }
    }

    @media screen and (min-width: 528px) {
        .templatePreview {
            width: 33.3%;
        }
    }

    @media screen and (min-width: 734px) {
        .templatePreview {
            width: 25%;
        }
    }
    /*page styles*/

    /*template preview*/
    .templatePreview {
        margin-top: 20px;
    }
    .templatePreview__image {
        width: 100%;
        cursor: pointer;
        display: block;
    }
    .templatePreview__name {
        text-align: center;
        font: 14px opensans_regular, sans-serif;
        margin-top: 10px;
    }
    /*template preview*/
}
