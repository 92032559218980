.modal_login {
    .body {
        @media screen and (width > $smallTabletBreakPoint) {
            width: 650px;
        }
    }
}

.alertContainer {
    margin-bottom: 15px;
}

.dontHaveAccount {
    margin-right: 15px;
}

.cols {
    display: flex;
    flex-direction: column;
    @media screen and (width > $smallTabletBreakPoint) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.cols__loginByEmailForm {
    width: 100%;
    @media screen and (width > $smallTabletBreakPoint) {
        width: 313px;
    }
}

.cols__or {
    display: none;
    margin-top: 4px;
    font-size: 16px;
    @media screen and (width > $smallTabletBreakPoint) {
        display: block;
    }
}

.cols__socialButtons {
    width: 190px;
    margin: 30px auto 0;
    @media screen and (width > $smallTabletBreakPoint) {
        margin: 0;
    }
}
