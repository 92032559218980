@value imagePath: landing/i;

html, body {
    height: 100%;
    min-width: 320px;
    margin: 0;
    padding: 0;
}
:global {
    .page {
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        font: 14px 'opensans_regular', sans-serif;
    }

    /*buttons*/
    .button {
        width: 100%;
        min-width: 104px;
        padding: 5px 32px;
        font: 16px 'opensans_regular', sans-serif;
        text-align: center;
        background-color: $color_pink;
        color: #ffffff;
        border: none;
        border-radius: 16px;
        cursor: pointer;
        transition: background-color .15s ease;
    }

    .button:focus,
    .button:active,
    .button:hover {
        background-color: $color_pinkBright;
        outline: none;
    }

    .button__big {
        padding: 10px 8px;
        border-radius: 21px;
    }
    @media screen and (width > $mobileBreakPoint) {
        .button {
            width: auto;
        }
        .button__big {
            width: auto;
            padding: 13px 42px;
            border-radius: 24px;
        }
    }
    /*buttons*/

    /*links*/
    a {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        padding: 0;
        margin: 0;
        outline: 0;
        font: 14px 'opensans_regular', sans-serif;
        text-decoration: none;
        color: $color_pink;
    }
    a:focus,
    a:hover {
        outline: 0;
        text-decoration: underline;
    }

    .link {
        color: #ffffff;
    }
    .link:focus,
    .link:hover {
        outline: 0;
        text-decoration: none;
        color: $color_pink;
    }

    .link__headerMenu {
        font-size: 16px;
    }

    .link_footer {
        flex: 0 0 auto;
        color: #929292;
        text-align: center;
    }
    .link_footer + .link_footer{
        margin-top: 10px;
    }
    @media screen and (width > $smallTabletBreakPoint) {
        .link_footer {
            text-align: left;
        }
    }

    .linkLikeButton {
        display: inline-block;
    }
    .linkLikeButton:active,
    .linkLikeButton:hover,
    .linkLikeButton:focus {
        text-decoration: none;
    }
    /*links*/

    /*icons*/
    .icon_it,
    .icon_en {
        display: inline-block;
        flex: 0 0 auto;
        width: 24px;
        height: 16px;
        background-size: contain;
        border-radius: 2px;
    }
    .icon_it {
        background-image: url('imagePath/it@2x.png#landing');
    }
    .icon_en {
        background-image: url('imagePath/en@2x.png#landing');
    }
    /*icons*/

    /*heading*/
    .heading {
        margin: 0;
        padding: 0;
        font: 30px 'opensans_light';
        text-align: center;
    }

    @media screen and (width > $mobileBreakPoint) {
        .heading {
            font-size: 36px;
        }
        .heading_mainPage {
            font-size: 45px;
        }
    }
    /*heading*/

    /*content*/
    .content {
        flex: 1 0 auto;
        width: 100%;
    }
    /*content*/

    .defaultPage {
        padding: 16px 10px 22px 10px;
    }
    .defaultPage__header {
        padding: 0 0 15px 0;
    }
    .defaultPage__button {
        padding: 20px 0 0 0;
        text-align: center;
    }

    @media screen and (width > $mobileBreakPoint) {
        .defaultPage {
            padding: 16px 22px 22px 22px;
        }
        .defaultPage__button {
            padding: 20px 0 0 0;
        }
    }
    @media screen and (width > $smallTabletBreakPoint) {
        .defaultPage {
            padding: 34px 22px 33px 22px;
        }
        .defaultPage__button {
            padding-top: 22px;
        }
    }
    @media screen and (width > $tabletBreakPoint) {
        .defaultPage {
            padding: 35px 0 45px 0;
            max-width: 984px;
            margin: 0 auto;
        }

        .defaultPage__header {
            padding: 0 0 31px 0;
        }
    }

    /* html text*/
    .htmlText h2 {
        margin: 0 0 20px 0;
        text-align: center;

        font-family: 'opensans_light', sans-serif;
        font-size: 24px;
        font-weight: normal;
    }

    .htmlText P {
        margin: 0;
        padding: 0;
    }

    .htmlText P + P {
        margin-top: 20px;
    }

    .htmlText .level2 {
        margin-left: 20px;
    }

    .htmlText .level3 {
        margin-left: 40px;
    }

    .htmlText .effectiveDate {
        text-align: center;

        color: #969696;
        font-size: 16px;
    }

    .htmlText .paragraphHeader {
        text-align: center;

        color: $color_pink;
        font-size: 20px;
    }

    .htmlText .paragraphCounterLevel1{
        font-family: opensans_bold, sans-serif;
        color: #ed6fb0;
    }

    .htmlText .paragraphCounterLevel2 {
        font-family: opensans_bold, sans-serif;
    }

    .pink {
        color: $color_pink;
    }

    .blue {
        color: #ed6fb0;
    }

    .bold {
        font-family: opensans_bold, sans-serif;
    }

    .centered {
        text-align: center;
    }

    /* html text */
}
