@value siteMaxWidth: 412px;
@value siteMinWidth: 300px;

:global {
    .sitesPage {
    }

    .sitesPageSites {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .sitesPageSites__site {
        width: siteMinWidth;
        max-width: siteMaxWidth;
        flex: 1 0 siteMinWidth;
    }

    .sitesSpacer { /* Note: vertical spacer */
        width: 100%;
        flex: 0 0 100%;

        margin-top: 44px;
    }

    @media screen and (width > $smallTabletBreakPoint) {
        .sitesPageSites {
            justify-content: flex-start;
        }

        .sitesSpacer:nth-child(4n + 2) { /* Note: horizontal spacer */
            width: auto;
            flex: 1 0 auto;
            margin: 0;
        }
    }

    .sitePreview {
    }

    .sitePreview__img {
        display: block; /* Note: to remove a free space at the bottom of a sitePreview */
        width: 100%;

        cursor: pointer;
    }

    .siteInfo {
        margin-top: 14px;
    }

    .defaultPage__content .siteLink {
        display: block;
        margin-bottom: 13px;

        font-size: 24px;
        color: $color_pink;
    }

    .siteDescription ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .siteDescription li + li {
        margin-top: 7px;
    }
}
