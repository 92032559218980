@value imagePath: landing/i;

:global {
    .page, body, html {
        height: 100%;
    }
    .page {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    /*header*/
    .header {
        height: 78px;
        flex: 0 0 auto;
        width: 100%;
    }

    .header__inner {
        height: 100%;
        width: 100%;
        max-width: 984px;
        margin: 0 auto;
        transition: padding .3s;
        display: flex;
        align-items: center;
        padding: 0 21px;
    }
    .header__logo,
    .header__loginButton,
    .header__menu,
    .header__mobileMenu,
    .header__langSwitcher,
    .header__button {
        flex: 0 0 auto;
    }
    .header__logo {
        width: 121px;
        height: 46px;
        margin-right: 30px;
    }
    .header__loginButton {
        margin-left: 15px;
    }
    .header__menu {
        margin: 0 auto;
    }
    .header__mobileMenu {
        width: 67px;
        height: 30px;
        border: 1px solid $color_pink;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: auto;
    }
    .header__langSwitcher {
        margin-left: 30px;
    }
    .header__button {
        margin-left: auto;
    }

    .header__button,
    .header__langSwitcher,
    .header__menu {
        display: none;
    }
    .header__langSwitcher_logged {
        margin-left: auto;
    }
    .header__mobileMenu {
        display: flex;
        margin-left: auto;
    }

    @media screen and (width > $mobileBreakPoint) {
        .header__button {
            display: block;
        }
        .header__mobileMenu {
            margin-left: 30px;
        }
        .header__mobileMenu_logged {
            margin-left: auto;
        }
    }

    @media screen and (width > $smallTabletBreakPoint) {
        .header__langSwitcher {
            display: block;
        }
        .header__mobileMenu_logged {
            margin-left: 30px;
        }
    }
    @media screen and (width > $tabletBreakPoint) {
        .header__menu {
            display: block;
        }
        .header__mobileMenu {
            display: none;
        }
        .header__inner {
            padding: 0;
        }
        .header__button {
            margin-left: 30px;
        }
        .header__langSwitcher_logged {
            margin-left: 30px;
        }
    }
    /*header*/
    /*header components*/
    .headerMenu {
        display: flex;
        padding: 0;
    }
    .headerMenu__item {
        list-style: none;
    }
    .headerMenu__item + .headerMenu__item {
        margin-left: 30px;
    }
    .logo {
        width: 121px;
        height: 46px;
        background-size: contain;
    }
    .logo_RIVcash {
        background-image: url('imagePath/logo@2x.png#landing');
    }
    .logo_CAMONcash {
        background-image: url('imagePath/camonCash@2x.png#landing');
    }
    .logo_IXcash {
        background-image: url('imagePath/ixCash@2x.png#landing');
    }
    .langSwitcher {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        flex-direction: row;
        position: relative;
        padding: 10px 0;
    }
    .langSwitcher__menu {
        visibility:  hidden;
        opacity: 0;
        z-index: -1;
        position: absolute;
        list-style-type: none;
        top: 100%;
        width: auto;
        background-color: #2a2a2a;
        padding: 10px 0;
        box-shadow: 0 0 15px 0 rgba(239, 0, 124, .25);
        border-radius: 5px;
        margin: 0;
        left: -83px;
    }

    .langSwitcher__menu_opened.langSwitcher__menu {
        z-index: 1;
        visibility: visible;
        opacity: 1;
    }

    .langSwitcher__menuItem {
        list-style: none;
    }
    .link_lang {
        height: 30px;
        padding: 0 22px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        .link_text {
            margin-right: 14px;
        }
    }

    .mobileMenuIcon {
        width: 25px;
        height: 15px;
        background-image: url('imagePath/mobMenu@2x.png#landing');
        background-size: contain;
        display: flex;
    }

    .pageId {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
    /*header components*/
}
