:global {

    /*index page sections*/
     .index {

     }
    .index__programs,
    .index__tools,
    .index__news {
        max-width: 984px;
        margin: 0 auto;
     }
    /*index page sections*/

    /*programs*/
    .programs {
        padding: 15px 10px 0 10px;
    }
    .programs__header {
        text-align: center;
        margin-bottom: 20px;
    }
    .programs__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .programPreview {
        width: 100%;
        background-color: #202020;
        padding: 15px 10px 20px 10px;
        border-radius: 5px;
    }
    .programPreview +.programPreview {
        margin-top: 10px;
    }
    .programPreview__percent,
    .programPreview__header {
        color: $color_pink;
    }
    .programPreview__percent {
        font: 73px opensans_bold, sans-serif;
    }
    .programPreview__header {
        font-size: 24px;
        margin: -10px 0 25px 0;
    }
    .programPreview__header_bold {
        font-family: opensans_bold, sans-serif;
    }
    .programPreview__text {
        line-height: 24px;
    }

    @media screen and (width > $mobileBreakPoint) {
        .programs {
            padding: 23px 22px 0 22px;
        }
        .programs__header {
            margin-bottom: 28px;
        }
        .programs__content {
            margin-top: -16px;
        }
        .programPreview {
            width: 48.4%;
            padding: 12px 22px 35px 22px;
        }
        .programPreview,
        .programPreview +.programPreview {
            margin-top: 16px;
        }
    }
    @media screen and (min-width: 600px) {
        .programs__content {
            margin-top: -20px;
        }
        .programPreview,
        .programPreview +.programPreview {
            margin-top: 20px;
        }
    }
    @media screen and (width > $smallTabletBreakPoint) {
        .programPreview {
            width: 31.6%;
        }
        .programPreview:last-child {
            width: 100%;
        }
        .programPreview__headerWrapper,
        .programPreview__percentWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .programPreview__headerWrapper {
            margin-left: 25px;
            height: 102px;
        }
        .programPreview__headerWrapper {
            .programPreview__header {
                margin: 0;
                padding: 0;
            }
        }
        .programPreview__headerContent {
            display: flex;
        }
    }

    @media screen and (width > $tabletBreakPoint) {
        .programs {
            padding: 33px 0 0 0;
        }
        .programs__header {
            margin-bottom: 40px;
        }
        .programPreview:last-child {
            margin-top: 24px;
        }
    }
    /*programs*/


    /*tools*/
    .tools {
        padding: 23px 10px 0 10px;
    }
    .tools__header {
        text-align: center;
        margin-bottom: 14px;
    }
    .tools__content {
        display: flex;
        flex-wrap: wrap;
    }
    .tools__decor {
        max-width: 100%;
        margin-top: 40px;
    }
    .tool {
        width: 100%;
    }
    .tool + .tool {
        margin-top: 28px;
    }
    .tool__header {
        color: $color_pink;
        font-size: 24px;
        margin: 0 0 20px 0;
    }
    .tool__text {
        font-size: 14px;
        line-height: 24px;
    }

    @media screen and (width > $mobileBreakPoint) {
        .tools {
            padding: 23px 22px 0 22px;
        }
        .tools__header {
            margin-bottom: 22px;
        }
        .tools__content {
            justify-content: space-between;
            margin-top: -31px;
        }
        .tool {
            width: 47%;
        }
        .tool,
        .tool + .tool {
            margin-top: 31px;
        }
    }
    @media screen and (width > $smallTabletBreakPoint) {
        .tools {
            padding: 33px 22px 0 22px;
        }
        .tools__header {
            margin-bottom: 35px;
        }
        .tools__content {
            margin-top: -43px;
        }
        .tool,
        .tool + .tool {
            margin-top: 43px;
        }
    }
    @media screen and (width > $tabletBreakPoint) {
        .tools__decor {
            margin-top: 70px;
        }
    }
    /*tools*/


    /*news*/
    .news {
        padding: 23px 10px 30px 10px;
    }
    .news__header {
        margin-bottom: 23px;
    }
    .news__content {
        display: flex;
        flex-wrap: wrap;
    }
    .newsItem {
        width: 100%;
    }
    .newsItem + .newsItem {
        margin-top: 35px
    }
    .newsItem__header {
        color: $color_pink;
        font-size: 18px;
        margin-bottom: 16px;

    }
    .newsItem__text {
        line-height: 24px;
        .htmlText {
            max-height: 168px;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
            p + p {
                margin-top: 13px;
            }
        }
    }
    .news__button {
        text-align: center;
        margin: 30px 0 0;
    }
    @media screen and (width > $mobileBreakPoint) {
        .news {
            padding: 23px 22px 45px 22px;
        }
        .newsItem {
            width: 47%;
        }
        .newsItem__header {
            margin-bottom: 18px;
        }
        .newsItem:last-child {
            display: none;
        }
        .news__content {
            justify-content: space-between;
        }
        .newsItem + .newsItem {
            margin-top: 0;
        }
    }
    @media screen and (width > $smallTabletBreakPoint) {
        .news__button {
            margin: 40px 0 0 0;
        }
    }
    @media screen and (width > $tabletBreakPoint) {
        .news {
            padding: 33px 22px 60px 22px;
        }
        .news__header {
            margin-bottom: 34px;
        }
        .newsItem__header {
            margin-bottom: 23px;
        }
        .newsItem:last-child {
            display: block;
        }
        .newsItem {
            width: 31%;
        }
    }
    /*news*/
}
