:global {
    .toolsPage {

    }
    .toolsPage__desc {
        padding: 0 0 15px 0;
    }

    .toolsPage__previews {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .toolPreview {
        width: 100%;
        background-color: #202020;
        border-radius: 5px;
        padding: 15px 10px 20px 10px;
    }
    .toolPreview + .toolPreview {
        margin-top: 10px;
    }
    .toolPreview__header {
        color: $color_pink;
        font-size: 24px;
        margin-bottom: 20px;
    }
    .toolPreview__text {
        line-height: 24px;
    }

    @media screen and (width > $mobileBreakPoint) {
        .toolPreview {
            padding: 27px 22px 35px 22px;
        }
        .toolPreview + .toolPreview {
            margin-top: 16px;
        }
    }

    @media screen and (min-width: 600px) {
        .toolPreview + .toolPreview {
            margin-top: 20px;
        }
    }

    @media screen and (width > $smallTabletBreakPoint) {
        .toolsPage__previews {
            margin-top: -20px;
        }
        .toolPreview {
            width: 48.4%;
            margin-top: 20px;
        }
    }

    @media screen and (width > $tabletBreakPoint) {
        .toolPreview {
            width: 31.6%;
        }
        .toolPreview:last-child {
            width: 100%;
            margin-top: 24px;
        }
    }
}
