.registerPage {
    margin: 0 auto;
    padding: 0 44px;

    @media screen and (width > $smallTabletBreakPoint) {
        width: 698px;
    }
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;

    font: 14px 'opensans_regular';

    margin-top: 30px;
    @media screen and (width > $tabletBreakPoint) {
        margin-top: 40px;
    }
}

.alreadyHaveAccount {
    margin-right: 15px;
}

.cols {
    display: flex;
    flex-direction: column;
    @media screen and (width > $smallTabletBreakPoint) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.cols__registrationForm {
    width: 100%;
    @media screen and (width > $smallTabletBreakPoint) {
        width: 341px;
    }
}

.cols__or {
    display: none;
    margin-top: 34px;
    font-size: 16px;
    @media screen and (width > $smallTabletBreakPoint) {
        display: block;
    }
}

.cols__socialButtons {
    width: 210px;
    margin: 30px auto 0;
    @media screen and (width > $smallTabletBreakPoint) {
        margin: 30px 0 0;
    }
}
