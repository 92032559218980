:global {
    .page_topBanner,
    .page_bottomBanner {
        flex: 0 0 auto;
        width: 100%;
    }
    .topBanner,
    .bottomBanner {
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: #111111;
        background-position: right -400px center;
        background-repeat: no-repeat;
        padding: 0 10px;
        overflow: hidden;
        transition: height .3s;
        align-items: center;
    }
    .topBanner {
        background-image: url('/static/images/landing/headBg.jpg');
        flex-direction: column;
        height: 207px;
    }
    .bottomBanner {
        background-image: url('/static/images/landing/bottomBg.jpg');
        flex-direction: row;
        height: 178px;
    }
    .bottomBanner__inner {
        display: flex;
        flex-direction: column;
        max-width: 984px;
        height: 100%;
        justify-content: space-evenly;
    }
    @media screen and (width > $mobileBreakPoint) {
        .bottomBanner__text {
            text-align: center;
        }
        .bottomBanner__button {
            text-align: center;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            align-items: center;
        }
        .bottomBanner__inner {
            padding: 10px 0;
        }
        .bottomBanner, .topBanner {
            padding: 0 20px;
        }
    }
    @media screen and (width > $smallTabletBreakPoint) {
        .bottomBanner__inner {
            padding: 10px 0;
        }
    }
    @media screen and (width > $tabletBreakPoint) {
        .bottomBanner__inner {
            flex-direction: row;
            padding: 0;
        }
        .bottomBanner__text{
            padding-top: 0;
        }
        .bottomBanner__text,
        .bottomBanner__button {
            display: flex;
            align-items: center;
            width: 50%;
        }
        .bottomBanner__button {
            justify-content: center;
            flex-direction: column;
        }
    }
    .bottomBanner__text {
        font: 24px 'opensans_light';
        line-height: 1.25;
        text-align: center;
    }
    .bottomBanner__button {
        text-align: center;
    }
    @media screen and (width > $mobileBreakPoint) {
        .topBanner {
            height: 268px;
        }
    }
    @media screen and (width > $tabletBreakPoint) {
        .topBanner,
        .bottomBanner {
            background-position: center;
        }
    }

    .topBanner__inner {
        max-width: 983px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    @media screen and (width > $smallTabletBreakPoint) {
        .topBanner__inner {
            padding: 16px 0;
        }
    }
    @media screen and (width > $tabletBreakPoint) {
        .topBanner__inner {
            padding: 22px 0;
        }
    }
    .topBanner__bannerText {
        font: 16px 'opensans_light';
        margin: 0 auto;
        max-width: 835px;
        display: none;
    }
    .topBanner__button {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    @media screen and (width > $mobileBreakPoint) {
        .topBanner__button {
            width: auto;
        }
    }
    @media screen and (min-width: 660px) {
        .topBanner__bannerText {
            display: block;
        }
    }
}
