.actionLink__aGSCM {
    font: 16px 'opensans_regular', sans-serif;
    color: #ef007c;
    text-decoration: none;
}

    .actionLink__aGSCM:active,
    .actionLink__aGSCM:focus,
    .actionLink__aGSCM:hover {
        text-decoration: underline;
    }


.alert__BmPbs {
    border-radius: 5px;
    border: 0;
    font: 14px 'opensans_regular', sans-serif;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.alert_info__Ym7DP {
    color: #ffffff;
    border-color: #202020;
    background-color: #202020;
}
.alert_success__-D8gb {
    color: #0f5132;
    border-color: #badbcc;
    background-color: #d1e7dd;
}
.alert_warning__SAV5C {
    color: #664d03;
    border-color: #ffecb5;
    background-color: #fff3cd;
}
.alert_error__ii8xw {
    color: #842029;
    border-color: #f5c2c7;
    background-color: #f8d7da;
}




.button__ntIjX {
    height: 34px;
    min-width: 110px;
    padding: 6px 15px;
    font: 14px 'opensans_regular', sans-serif;
    text-align: center;
    background-color: #ef007c;
    color: #ffffff;
    border-radius: 17px;
}

.button__ntIjX:focus,
    .button__ntIjX:active,
    .button__ntIjX:hover {
        background-color: #ed6fb0;
    }

@media screen and (min-width: 480px) {

.button__ntIjX {
        width: auto
}
    }

.button_disabled__6D8vb {
    opacity: .5;
    transition: .3s ease .5s;
}

.button__inner__c-cZq {
    pointer-events: none;
}

.button_spinner__HTaun {
    cursor: default !important;
}






.buttonsContainer__s2ndd {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

@media screen and (min-width: 480px) {

.buttonsContainer__s2ndd {
        flex-direction: row
}
    }

.buttonsContainer__button__cH2bj {
    height: 34px;
    max-width: 100%;
    width: 100%;
}

.buttonsContainer__button__cH2bj + .buttonsContainer__button__cH2bj {
        margin: 11px 0 0 0;
    }

@media screen and (min-width: 480px) {

.buttonsContainer__button__cH2bj {
        min-width: 110px;
        max-width: none;
        width: auto
}
        .buttonsContainer__button__cH2bj + .buttonsContainer__button__cH2bj {
            margin: 0 0 0 11px;
        }
    }


@media screen and (min-width: 480px) {.confirmation__8Qjp6 .content__BsaRI {
            padding-top: 60px
    }
        }


.contextMenuLink__hint__7fTeh {
    border-color: #ffffff;
    background-color: #202020;
    z-index: 20;
}

.contextMenuLink__hint__7fTeh.contextMenuLink__hint_right__7aKDe {
        margin-left: 10px;
    }

.contextMenuLink__hint__7fTeh.contextMenuLink__hint_left__SI-ud {
        margin-left: -14px;
    }

.contextMenuLink__hintArrow__WseXM:after {
        border-top-color: #202020;
    }

.contextMenuLink__hintArrow__WseXM:before {
         border-top-color: #ffffff;
    }
.contextMenuLink__hintArrow_right__pob5q:before,
.contextMenuLink__hintArrow_right__pob5q:after {
    right: 18px;
}



.dropDownMenu__C3vZ5 {
    font-family: 'opensans_regular', sans-serif;
    border-color: #7b4e65;
    border-radius: 0 0 17px 17px;
    background-color: #343434;

}
.dropDownMenu__item__Lg30g:hover,
    .dropDownMenu__item__Lg30g:focus {
        background-color: #404040;
    }


.rowsPerPageText__V4xOK {
    font-family: 'opensans_regular', sans-serif;
    color: #ffffff;
}


.gridPlaceholder__W-iNG {
    font-family: 'opensans_regular';
}


.heading__INJxz {
    font-family: 'opensans_regular', sans-serif;
    font-weight: normal;
}
.heading_level_1__rj3kJ {
    font-size: 30px;
}
.heading_level_2__ULOH2 {
    font-size: 20px;
}
.heading_level_3__4AnpO {
    font-family: 'opensans_bold', sans-serif;
    font-size: 18px;
}


.link__OtPEm {
    font: 16px 'opensans_regular', sans-serif;
    color: #ef007c;
    text-decoration: none;
}

    .link__OtPEm:active,
    .link__OtPEm:focus,
    .link__OtPEm:hover {
        text-decoration: underline;
    }




.pageItem__CWi4e:first-child .pageLink__ytGUr {
        border-radius: 17px 0 0 17px;
    }

.pageItem__CWi4e:last-child .pageLink__ytGUr {
        border-radius: 0 17px 17px 0;
    }

.pageLink__ytGUr {
    font-family: 'opensans_regular';
    font-size: 14px;
    padding: 0px 9px;
    height: 34px;
}

.pageLink_normal__yXG4I {
    color: #ffffff;
    border-color: #454545;
    background-color: #343434;
}

.pageLink_normal__yXG4I:hover,
    .pageLink_normal__yXG4I:focus {
        color: #ffffff;
        background-color: #5c5c5c;
        text-decoration: none;
    }

.pageLink_normal__yXG4I.disabled__kM9Sx:hover,
        .pageLink_normal__yXG4I.disabled__kM9Sx:focus {
            color: #ffffff;
            border-color: #454545;
            background-color: #343434;
        }

.pageLink_active__8krTN {
    color: #ffffff;
    border-color: #454545;
    background-color: #5c5c5c;
}

.pageLink_active__8krTN:hover,
    .pageLink_active__8krTN:focus {
        color: #ffffff;
        background-color: #5c5c5c;
        text-decoration: none;
    }

.pageLink_active__8krTN.disabled__kM9Sx,
        .pageLink_active__8krTN.disabled__kM9Sx:hover,
        .pageLink_active__8krTN.disabled__kM9Sx:focus {
            opacity: .5;
            color: #ffffff;
            border-color: #454545;
            background-color: #5c5c5c;
        }


.td__djU-A {
    padding: 6px 11px;

    font-family: 'opensans_regular';

    color: #ffffff;
    background-color: #202020;

    text-align: left;
}







.menuItem__pSiQx {
    height: 44px;
    margin: 0 15px;
}
.menuItem_open__V3Evx,
.menuItem__pSiQx:active {
    background-color: transparent;
}


.menu__G7irP {
    height: 44px;
    background: transparent;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: auto;
    align-items: center;
}


.menuItemLink__k7RwR.actionLink__Fem0X,
    .menuItemLink__k7RwR.actionLink__Fem0X .link__gOgAG,
    .menuItemLink__k7RwR.link__gOgAG {
        color: #ffffff;
        padding: 0;
    }

        .menuItemLink__k7RwR.actionLink__Fem0X.link_open__FifkW,
        .menuItemLink__k7RwR.actionLink__Fem0X.link_active__qKnGG,
        .menuItemLink__k7RwR.actionLink__Fem0X:active,
        .menuItemLink__k7RwR.actionLink__Fem0X:focus,
        .menuItemLink__k7RwR.actionLink__Fem0X:hover,
        .menuItemLink__k7RwR.actionLink__Fem0X .link__gOgAG.link_open__FifkW,
        .menuItemLink__k7RwR.actionLink__Fem0X .link__gOgAG.link_active__qKnGG,
        .menuItemLink__k7RwR.actionLink__Fem0X .link__gOgAG:active,
        .menuItemLink__k7RwR.actionLink__Fem0X .link__gOgAG:focus,
        .menuItemLink__k7RwR.actionLink__Fem0X .link__gOgAG:hover,
        .menuItemLink__k7RwR.link__gOgAG.link_open__FifkW,
        .menuItemLink__k7RwR.link__gOgAG.link_active__qKnGG,
        .menuItemLink__k7RwR.link__gOgAG:active,
        .menuItemLink__k7RwR.link__gOgAG:focus,
        .menuItemLink__k7RwR.link__gOgAG:hover {
            color: #ef007c;
            text-decoration: none;
        }
    .menuItemLink__k7RwR.actionLink__Fem0X:active .link__gOgAG, .menuItemLink__k7RwR.actionLink__Fem0X:focus .link__gOgAG, .menuItemLink__k7RwR.actionLink__Fem0X:hover .link__gOgAG {
                color: #ef007c;
                text-decoration: none;
            }
    .menuItemLink__k7RwR {
    -webkit-tap-highlight-color: transparent;
}


.submenuItem__pmPV0 {
    height: 30px;
}


.submenu__6rTk2 {
    background-color: #2a2a2a;
    box-shadow: 0 0 15px 0 rgba(239, 0, 124, .25);
    padding: 10px 0;
}


.submenuItemLink__GWvWo {
    font: 14px 'opensans_regular', sans-serif;
    color: #ffffff;
    height: 30px;
    padding: 0 22px;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}

.submenuItemLink__GWvWo.link__sbYu2:hover,
.submenuItemLink__GWvWo.link__sbYu2:active,
.submenuItemLink__GWvWo.link__sbYu2:focus,
.actionLink__Jrwqc:hover .submenuItemLink__GWvWo,
.actionLink__Jrwqc:active .submenuItemLink__GWvWo,
.actionLink__Jrwqc:focus .submenuItemLink__GWvWo,
.submenuItemLink__GWvWo:hover,
.submenuItemLink__GWvWo:active,
.submenuItemLink__GWvWo:focus {
    background-color: transparent;
    color: #ef007c;
}

.submenuItemLink_active__NE7-Q {
    color: #ef007c;
}




.table__E3E3k {
    border-color: #454545;
    border-radius: 5px;
    border-collapse: separate;
    border-spacing: 2px 2px;
}

.table_disabled__DH3bd:after {
    background-color: rgba(0, 0, 0, .5);
}


.th__xOb8N, .td__I0tTO {
    border: none;
    vertical-align: top;
}

.th__xOb8N {
    background-color: #202020;
    text-align: left;
    padding: 6px 11px;
}

.th__xOb8N,
.th__xOb8N .sortLink__SWks6,
.td__I0tTO {
    font-family: 'opensans_regular', sans-serif;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    color: #ffffff;
}

.td__I0tTO {
    text-align: left;
    padding: 0;
}

.td_span__lgyTb {
    background-color: #2a2a2a;
}

.table_striped__J0SqE .tbody__cgpvE .tr__nIQ1m:nth-of-type(odd) {
        background-color: #171717;
    }

.table_striped__J0SqE .tbody__cgpvE .tr__nIQ1m:nth-of-type(even) {
        background-color: #202020;
    }

.sortLink__SWks6:hover,
    .sortLink__SWks6:active,
    .sortLink__SWks6:focus {
        color: #ef007c;
    }

.sortLink_desc__NoYLT:after, .sortLink_asc__WK3J2:after {
        content: '';
        vertical-align: top;
        margin-left: 10px;
        flex: 0 0 auto;

        background-image: url('styles/libs/react-redux-app/table/i/arrow@2x.png#table');
    }

.sortLink_desc__NoYLT:hover:after, .sortLink_desc__NoYLT:active:after, .sortLink_desc__NoYLT:focus:after, .sortLink_asc__WK3J2:hover:after, .sortLink_asc__WK3J2:active:after, .sortLink_asc__WK3J2:focus:after {
            background-image: url('styles/libs/react-redux-app/table/i/arrowOver@2x.png#table');
        }

.sortLink_desc__NoYLT:before, .sortLink_asc__WK3J2:before {
        content: none;
    }

.sortLink_desc__NoYLT:after {
    transform: rotate(180deg);
}






.table__VlfUY {
    border-color: #454545;
    border-radius: 5px;
    border-collapse: separate;
    border-spacing: 2px 2px;
}

.table_disabled__80r0d:after {
    background-color: rgba(0, 0, 0, .5);
}


.th__1jnrg, .td__m16hj {
    border: none;
    vertical-align: top;
}

.th__1jnrg {
    background-color: #202020;
    text-align: left;
    padding: 6px 11px;
}

.th__1jnrg,
.th__1jnrg .sortLink__QPdun,
.td__m16hj {
    font-family: 'opensans_regular', sans-serif;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    color: #ffffff;
}

.td__m16hj {
    text-align: left;
    padding: 0;
}

.td_span__ayeti {
    background-color: #2a2a2a;
}

.table_striped__7Oimi .tbody__n2IVN .tr__kRjI4:nth-of-type(even) {
        background-color: #171717;
    }

.table_striped__7Oimi .tbody__n2IVN .tr__kRjI4:nth-of-type(odd) {
        background-color: #202020;
    }

.sortLink__QPdun:hover,
    .sortLink__QPdun:active,
    .sortLink__QPdun:focus {
        color: #ef007c;
    }

.sortLink_desc__ke7Wp:after, .sortLink_asc__NQM2S:after {
        content: '';
        vertical-align: top;
        margin-left: 10px;
        flex: 0 0 auto;

        background-image: url('styles/libs/react-redux-app/table/i/arrow@2x.png#table');
    }

.sortLink_desc__ke7Wp:hover:after, .sortLink_desc__ke7Wp:active:after, .sortLink_desc__ke7Wp:focus:after, .sortLink_asc__NQM2S:hover:after, .sortLink_asc__NQM2S:active:after, .sortLink_asc__NQM2S:focus:after {
            background-image: url('styles/libs/react-redux-app/table/i/arrowOver@2x.png#table');
        }

.sortLink_desc__ke7Wp:before, .sortLink_asc__NQM2S:before {
        content: none;
    }

.sortLink_desc__ke7Wp:after {
    transform: rotate(180deg);
}


.container_opened__lbOyM:before {
        height: 17px;
        border-color: #7b4e65;
        background-color: #343434;
        border-bottom: none;
    }




.checkbox__Y4ebA {
    position: relative;
    width: 20px;
    height: 20px;
}

.checkbox__Y4ebA input[type=checkbox] {
        position: absolute;
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
    }

.checkbox__Y4ebA input[type=checkbox]:checked + .checkbox__customStyle__v213u {
            border-color: #ef007c;
            background-color: #ef007c;
        }

.checkbox__Y4ebA input[type=checkbox]:checked + .checkbox__customStyle__v213u:after {
                color: #ffffff;
            }

.checkbox__Y4ebA input[type=checkbox]:checked + .checkbox__customStyle__v213u:after {
            content: 'L';
            transform: rotateY(180deg) rotateZ(-45deg);
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            font-weight: 500;
            text-align: center;
        }

.checkbox__customStyle__v213u {
    display: block;
    width: 20px;
    height: 20px;

    border-radius: 5px;
    border: 2px solid;
    background: none;
    font-size: inherit;
    cursor: pointer;

    transition: .3s ease;

    border-color: #454545;
    background-color: #343434;
}

.checkbox__customStyle__v213u:hover {
        border-color: #ef007c;
    }

.checkbox_disabled__l3qEE input[type=checkbox]  + .checkbox__customStyle__v213u,
        .checkbox_disabled__l3qEE input[type=checkbox]:checked + .checkbox__customStyle__v213u {
            cursor: default;
            border-color: #343434;
            background-color: #252525;
        }


.dayMonthYear__day__Mg5KE,
.dayMonthYear__year__ccspe {
    max-width: 90px;
}




.field__e0Uw1 + .field__e0Uw1 {
    margin-top: 18px;
}

.label__UEaxV {
    font: 14px 'opensans_regular', sans-serif;
    text-align: left;
    margin-left: 0;
    padding-right: 0;
    white-space: normal;
    height: auto;
}
.label_required__FXPJK::after {
    content: "*";
    color: #ff0000;
}
.field_error__Mkl7b {
    color: #ff0000;
}
.helpBlock__i-aSV {
    font: 12px 'opensans_regular', sans-serif;
}
.field_column__ALZhC>.label__UEaxV {
        margin-bottom: 10px;
    }
.field_column__ALZhC>.controls__N1l1E,
    .field_column__ALZhC>.controls_labeled__CM67v {
        width: 100%;
    }




.formError__U2rob {
    padding: 15px;
    font: 14px 'opensans_bold', sans-serif;
    color: #ff0000;
}




.input__mo6j2 {
    font-family: 'opensans_regular', sans-serif;

    color: #ffffff;
    border-color: #454545;
    border-radius: 17px;
    background-color: #343434;

    position: relative;
}

.input__mo6j2.input_showAsFocused__lNH1F,
    .input__mo6j2:active,
    .input__mo6j2:focus {
        border-color: #7b4e65;
    }

.input_error__hTw4N,
    .input_error__hTw4N.input_showAsFocused__lNH1F,
    .input_error__hTw4N:active,
    .input_error__hTw4N:focus {
        border-color: #ff0000;
    }

.input_disabled__zP4IC,
    .input_disabled__zP4IC.input_showAsFocused__lNH1F,
    .input_disabled__zP4IC:active,
    .input_disabled__zP4IC:focus  {
        color: #7a7a7a;
        border-color: #343434;
        background-color: #252525;
    }


@media screen and (min-width: 480px) {.simpleInput__Gdyrl {
        width: 313px
}
    }




.container__input__W8wx- {
    font-family: 'opensans_regular', sans-serif;

    color: #ffffff;
    border-color: #454545;
    border-radius: 17px;
    background-color: #343434;

    padding: 0 90px 0 12px;
}

.container__input__W8wx-:active,
    .container__input__W8wx-:focus {
        border-color: #7b4e65;
    }

.container__input_error__J2zVN,
    .container__input_error__J2zVN:active,
    .container__input_error__J2zVN:focus {
        border-color: #ff0000;
    }
.container__input_disabled__LDdso,
    .container__input_disabled__LDdso:active,
    .container__input_disabled__LDdso:focus {
        color: #7a7a7a;
        border-color: #343434;
        background-color: #252525;
    }

.container__hint__s2Guh {
    font-family: 'opensans_regular', sans-serif;
}




.container__input__0E6JY {
    font-family: 'opensans_regular', sans-serif;

    color: #ffffff;
    border-color: #454545;
    border-radius: 17px;
    background-color: #343434;
}

.container__addon__YQMpq {
    font-family: 'opensans_regular', sans-serif;

    color: #ffffff;
    border-color: #454545;
    background-color: #2a2a2a;
}

.container__addon__YQMpq:last-child {
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
    }

.container__input_active__wqOhZ,
.container__addon_active__SaAsk {
    border-color: #7b4e65;
}

.container__input_error__keCw5,
.container__addon_error__RaBt- {
    border-color: #ff0000;
}

.container__input_disabled__sPNXC {
    color: #7a7a7a;
    border-color: #343434;
    background-color: #252525;
}

.container__addon_disabled__Rk4eK {
    color: #7a7a7a;
    border-color: #343434;
}





.multiSelect__rlVXg {
    font-family: 'opensans_regular', 'sans-serif';
    color: #ffffff;
}


.multiSelect_opened__4h6xy:before {
    display: block;
    content: "";
    background: #343434;
    position: absolute;
    height: 17px;
    left: 0;
    right: 0;
    bottom: 0;
    border-left: solid 1px #7b4e65;
    border-right: solid 1px #7b4e65;
}

.multiSelect_error__XvpBi.multiSelect_opened__4h6xy:before,
    .multiSelect_error__XvpBi .multiSelect__optionListWrapper__4l49J {
        border-color: #ff0000;
    }

.multiSelect_disabled__rnzHy {
    color: #7a7a7a;
}

.multiSelect__control__gCdXm {
    border-color: #454545;
    border-radius: 17px;
    background-color: #343434;
}

.multiSelect__control_opened__zKpai {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.multiSelect__control_focused__YyfNi {
    border-color: #7b4e65;
}

.multiSelect__control_error__Vxbgj {
    border-color: #ff0000;
}

.multiSelect__control_disabled__YMOwo {
    border-color: #343434;
    background-color: #252525;
}

.multiSelect__values__2aD52 {
    padding: 0 10px 0 0;
}

.multiSelect__arrow__nMVDY {
    width: 9px;
    margin-top: 2px;
}

.multiSelect__arrowImage__D74A0 {
    background-image: url('styles/libs/react-redux-app/form/MultiSelect/i/arrowOpen@2x.png#form');
    border: none;
}

.multiSelect__arrowImage_opened__XSnse {
    background-image: url('styles/libs/react-redux-app/form/MultiSelect/i/arrowClose@2x.png#form');
    top: 0;
}

.multiSelect__optionListWrapper__4l49J {
    border-color: #7b4e65;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
    background-color: #343434;
}

.multiSelect__option_selected__xk1Xe,
.multiSelect__option_focused__XGm7d {
    background-color: #404040;
}




.radioButton__6j0mX {
    min-height: 20px;
}

.radioButton__6j0mX + .radioButton__6j0mX {
        margin-left: 0;
        margin-top: 10px;
    }

.radioButton__6j0mX {

    display: block;
    text-align: left;
    padding: 0 0 0 32px;
}

.radioButton__6j0mX label {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

.radioButton__6j0mX i {
        width: 20px;
        height: 20px;
        border: 2px solid #454545;
        background-color: #343434;
    }

.radioButton__6j0mX i:hover {
            border-color: #ef007c;
        }

.radioButton__6j0mX i:before {
            width: 8px;
            height: 8px;
            margin: 4px;
            background-color: #ffffff;
            transition: .3s ease;
        }

.radioButton__6j0mX input[type=radio]:checked + i {
        border-color: #ef007c;
        background-color: #ef007c;
        transition: .3s ease;
    }

.radioButton_disabled__2XkIi label {
        cursor: default;
    }

.radioButton_disabled__2XkIi i,
        .radioButton_disabled__2XkIi i:hover,
        .radioButton_disabled__2XkIi input[type=radio]:checked + i,
        .radioButton_disabled__2XkIi input[type=radio]:checked + i:hover {
            border-color: #343434;
            background-color: #252525;
        }




.textarea__O5xmk {
    font-family: 'opensans_regular', sans-serif;

    color: #ffffff;
    border-color: #454545;
    border-radius: 17px;
    background-color: #343434;
}

.textarea__O5xmk:active,
    .textarea__O5xmk:focus {
        border-color: #7b4e65;
    }

.textarea_error__ll7N-,
    .textarea_error__ll7N-:active,
    .textarea_error__ll7N-:focus {
        border-color: #ff0000;
    }

.textarea_disabled__pCqBO,
    .textarea_disabled__pCqBO:active,
    .textarea_disabled__pCqBO:focus {
        color: #7a7a7a;
        border-color: #343434;
        background-color: #252525;
    }

.textareaWrapper__counter__f-QIZ {
    color: #ffffff;
    background-color: rgba(52, 52, 52, .7);
}


.modal_fillBackground__H-noc {
    background-color: rgba(0, 0, 0, .8);
}

.bodyWrapper_horizontalHelper__v-PNc {
    flex: 1 0 auto;
}

@media screen and (min-width: 480px) {

.bodyWrapper_horizontalHelper__v-PNc {
        flex: 0 0 auto
}
    }

.body__i7YBC {
    border: none;
    border-radius: 0;

    width: 480px;

    background-color: #171717;

    flex: 1 1 auto;
}

@media screen and (min-width: 480px) {

.body__i7YBC {
        border: 1px solid #454545;
        border-radius: 5px;
        flex: 0 0 auto
}
    }

.header__OV3Xr {
    height: 49px;
    background-color: transparent;
    margin: 0;
    padding: 0;
}

@media screen and (min-width: 480px) {

.header__OV3Xr {
        height: 38px
}
    }

.headerContent__10Kls {
    width: 97%;
    text-align: left;
    padding: 28px 22px 0 22px;
    font: 16px 'opensans_regular', "sans-serif";
    color: #ffffff;
}

@media screen and (min-width: 480px) {

.headerContent__10Kls {
        padding-top: 17px
}
    }

.controls__dOpzF {
    top: 33px;
    right: 22px;
}

@media screen and (min-width: 480px) {

.controls__dOpzF {
        top: 22px
}
    }

.closeButton__RGLHy {
    z-index: 1;
    background-image: url('styles/libs/react-redux-app/modal/Modal/i/close@2x.png#modal');
}

.content__bjXnX {
    padding: 30px 22px;
    font: 14px 'opensans_regular';
}

@media screen and (min-width: 480px) {

.content__bjXnX {
        padding: 30px 44px
}
    }
@media screen and (min-width: 480px) {
.content_noHeader__SEOZ4 {
        padding-top: 60px
}
    }

.footer__2TA9U {
    border: none;
    padding: 0 22px 22px 22px;
    margin: 0;
}

@media screen and (min-width: 480px) {

.footer__2TA9U {
        padding: 0 44px 44px 44px
}
    }


.modalsStack__D-0Dp {
    position: relative;
    z-index: 20;
}


@font-face {
    font-family: 'opensans_bold';
    src: url('../fonts/OpenSans/OpenSans-Bold-webfont.eot');
    src: url('../fonts/OpenSans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans/OpenSans-Bold-webfont.woff') format('woff'),
    url('../fonts/OpenSans/OpenSans-Bold-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans_regular';
    src: url('../fonts/OpenSans/OpenSans-Regular-webfont.eot');
    src: url('../fonts/OpenSans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans/OpenSans-Regular-webfont.woff') format('woff'),
    url('../fonts/OpenSans/OpenSans-Regular-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


html, body {
    height: 100%;
    min-width: 320px;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    color: #ffffff;
    background: #171717;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}




.previewSectionWrap__dIvG9 {
    margin-top: 25px;
}

.previewSectionHeader__NCJUn {
    margin-bottom: 10px;
}

.headerWrap__c6UhN {
    margin-top: 40px;
    margin-bottom: 20px;
}

.buttonsContainer__button__-1vEY {
    width: 100%;
}

.emptyField__FGweB {
    height: 20px;
    margin: 18px 0;
}

.savedBanners__uD8qO {
    margin-top: 40px;
}


.emptyField__udO7i {
    height: 20px;
    margin: 18px 0;
}


.savedBannerWrap__z-1cf {
    margin-top: 20px;
}

    .savedBannerWrap__z-1cf + .savedBannerWrap__z-1cf {
        margin-top: 30px;
    }

.bannerName__yUH6m {
    margin-bottom: 10px;
}

.buttonsWrap__aLL4r {
    margin-top: 10px;
}

.buttonsContainer__U3LJP {
    justify-content: flex-start;
}


.rssFeeds__B3xZ- {
    margin-top: 40px;
}


.linkRenderersWrap__Ka1-e:empty {
    display: none;
}


.windowFieldsWrap__GwgN- {
    margin-top: 20px;
}


.horizontalBanner__DQhia {
    margin-top: 18px;
}


.textArea__5rm3w {
    min-height: 200px;
    resize: vertical;
    word-break: break-all;
}


.body__U7lS7 {
    width: 100%;
}

    @media screen and (min-width: 804px) {.body__U7lS7 {
        width: 804px
}
    }


.status__QJXwZ {
    font-family: 'opensans_bold';
}

.status_active__2UBWZ {
    color: #23bd0b;
}

.status_removed__aqURt {
    color: #ff0000;
}

.status_detached__rbYG- {
    color: #555555;
}


.status__X8-Vn {
    font-family: 'opensans_bold';
}

.status_active__qeAlP {
    color: #23bd0b;
}

.status_deleted__-G5YN {
    color: #ff0000;
}

.status_pending__jgo8C {
    color: #cd994f;
}

.status_suspended__gDwYf {
    color: #ee6315;
}


.balancePart__pRWOB {
    overflow: hidden;
    height: 44px;
}

.balancePart__name__nu8K9 {
    font: 12px 'opensans_regular';
    text-transform: uppercase;
    color: #8e8e8e;
}

.balancePart__value__6GT0e {
    font: 16px 'opensans_regular';
    color: #ffffff;
    margin-top: 4px;
}

.balancePart__value_link__fneSf {
    text-decoration: underline;
}

.balancePart__value_link__fneSf:focus,
    .balancePart__value_link__fneSf:active,
    .balancePart__value_link__fneSf:hover {
        text-decoration: none;
    }

.balancePart__value_negative__-biuA,
    .balancePart__value_negative__-biuA:focus,
    .balancePart__value_negative__-biuA:active,
    .balancePart__value_negative__-biuA:hover {
        color: #ff0000;
    }


.textarea__pZTso {
    min-height: 110px;
    resize: vertical;
}


.status__JCLR8 {
    font-family: 'opensans_bold';
}

.status_active__ceEsS {
    color: #23bd0b;
}

.status_deleted__oa3qW {
    color: #ff0000;
}

.status_locked__nHkvN {
    color: #555555;
}

.status_pending__s1YhA {
    color: #cd994f;
}

.status_suspended__Cifhu {
    color: #ee6315;
}


.chartContainer__Q3Mw1 {
    width: 100%;
}


.actionLink__vOwIs {
    margin-left: 10px;
    vertical-align: middle;
    margin-bottom: 4px;
}

.icon__LeTwf {
    background-image: url('modules/core/chart/components/chartActionLinkHOC/i/chartPink@2x.png#rivcash');
}

.icon__LeTwf:hover {
        background-image: url('modules/core/chart/components/chartActionLinkHOC/i/chartPinkBright@2x.png#rivcash');
    }


.button__H7UQE {
    font-size: 16px;
    min-width: 137px;
    height: 43px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 21.5px;
}


.createButton__bNTWQ {
    display: block;
    margin-top: 30px;
    margin-bottom: 40px;
    width: 100%;
}

    @media screen and (min-width: 480px) {.createButton__bNTWQ {
        width: 231px
}
    }


.button__zIzHg {
    background-color: #21a40b;
}

    .button__zIzHg:active,
    .button__zIzHg:focus,
    .button__zIzHg:hover {
        background-color: #3bd122;
    }


.button__k3FLs {
    color: #ef007c;
    border: 1px solid #ef007c;
    background: transparent !important;
}

    .button__k3FLs:active,
    .button__k3FLs:hover,
    .button__k3FLs:focus {
        border-color: #ed6fb0;
        color: #ed6fb0;
    }

.button_spinner__zALag {
    background-color: #ef007c;
}


.multiLineButton__iRNOf {
    height: auto;
    min-height: 34px;
}


.button__f-6rE {
    background-color: #ef0000;
}

    .button__f-6rE:active,
    .button__f-6rE:focus,
    .button__f-6rE:hover {
        background-color: #ff7e7e;
    }


.button__c0PUT {
    background-color: #ee6315;
}

    .button__c0PUT:active,
    .button__c0PUT:focus,
    .button__c0PUT:hover {
        background-color: #ff8744;
    }


.field__946q8 {
    position: relative;
    display: flex;
    flex-direction: column;
}

.label__vWDle {
    width: auto;
    margin-left: 32px;
}

.controls_labeled__c4Ur2 {
    width: auto;
}

.control__JbbFG {
    position: absolute;
    top: 0;
}

.label_required__8huTR::after {
    content: none;
}


.column__SHYBZ {
    width: 100%;
}
    .column__SHYBZ + .column__SHYBZ {
        margin-top: 18px;
    }
    @media screen and (min-width: 480px) {.column__SHYBZ {
        width: 313px
}
    }
    @media screen and (min-width: 768px) {
        .column__SHYBZ + .column__SHYBZ {
            margin-top: 0;
            margin-left: 88px;
        }
    }

@media screen and (min-width: 480px) {

.column_withTooltip__eNjFZ {
        width: 341px
}
    }

@media screen and (min-width: 768px) {
        .column_withTooltip__eNjFZ + .column__SHYBZ {
            margin-left: 60px;
        }
    }
@media screen and (min-width: 480px) {
.column_withEmptyTooltip__r60GL {
        padding-right: 28px
}
    }

.column_empty__5BKVo + .column__SHYBZ,
.column__SHYBZ + .column_empty__5BKVo {
    margin-top: 0;
}




.rw-date-picker .rw-widget-picker {
            border-color: #454545;
            border-radius: 17px;
            background-color: #454545;
        }

.rw-date-picker .rw-widget-picker .rw-input {
                color: #ffffff;
                background-color: #343434;
            }

.rw-date-picker .rw-widget-picker .rw-input-addon {
                border-color: #454545;
                background-color: #343434;
            }

.rw-date-picker .rw-widget-picker .rw-input-addon .rw-i-calendar {
                    background-image: url('modules/core/form/components/DateTimePicker/i/date@2x.png#dateTime');
                }

.rw-date-picker .rw-widget-picker .rw-input-addon .rw-i-calendar path {
                        display: none;
                    }

.rw-date-picker .rw-widget-picker .rw-input-addon:hover .rw-i-calendar {
                        background-image: url('modules/core/form/components/DateTimePicker/i/dateOver@2x.png#dateTime');
                    }

.rw-date-picker.rw-state-focus .rw-widget-picker, .rw-date-picker.rw-state-focus .rw-widget-picker .rw-input-addon {
                    border-color: #7b4e65;
                }

.rw-date-picker.rw-state-disabled .rw-widget-picker {
                border-color: #343434;
                background-color: #252525;
            }

.rw-date-picker.rw-state-disabled .rw-widget-picker .rw-input {
                    color: #7a7a7a;
                    background-color: #252525;
                }

.rw-date-picker.rw-state-disabled .rw-widget-picker .rw-input-addon {
                    border-color: #343434;
                    background-color: #252525;
                }

.rw-date-picker.rw-state-disabled .rw-widget-picker .rw-input-addon .rw-i-calendar {
                        opacity: .5;
                    }

.rw-date-picker.rw-state-disabled .rw-widget-picker .rw-input-addon:hover .rw-i-calendar {
                            background-image: url('modules/core/form/components/DateTimePicker/i/date@2x.png#dateTime');
                        }

.rw-date-picker .rw-popup {
            border-color: #454545;
            border-radius: 17px;
            background-color: #343434;
        }

.rw-date-picker .rw-calendar .rw-calendar-header .rw-calendar-btn {
                    color: #ffffff;
                }

.rw-date-picker .rw-calendar .rw-calendar-header .rw-calendar-btn:hover {
                        background-color: #404040;
                    }

.rw-date-picker .rw-calendar .rw-calendar-header .rw-calendar-btn:focus {
                        border-color: #ef007c;
                    }

.rw-date-picker .rw-calendar .rw-calendar-grid {
                background-color: #343434;
            }

.rw-date-picker .rw-calendar .rw-calendar-grid .rw-head-cell {
                    color: #ffffff;
                    border-color: #454545;
                }

.rw-date-picker .rw-calendar .rw-calendar-grid .rw-cell {
                    color: #ffffff;
                }

.rw-date-picker .rw-calendar .rw-calendar-grid .rw-cell:hover {
                        background-color: #404040;
                    }

.rw-date-picker .rw-calendar .rw-calendar-grid .rw-cell:focus {
                        border-color: #ef007c;
                    }

.rw-date-picker .rw-calendar .rw-calendar-grid .rw-cell.rw-state-selected, .rw-date-picker .rw-calendar .rw-calendar-grid .rw-cell.rw-state-selected:hover {
                            color: #ffffff;
                            border-color: #ef007c;
                            background-color: #ef007c;
                        }

.rw-date-picker .rw-calendar .rw-calendar-grid .rw-cell-off-range {
                    opacity: .5;
                }

.rw-date-picker .rw-time-input {
            color: #ffffff;
            border-color: #454545;
            background-color: #343434;
        }


.elementsFor2Columns__-oI6y {
    width: 100%;
    max-width: 714px;
}


.fieldTooltipWrapper__YzHkC {
    display: flex;
    flex-direction: row;
}

.fieldTooltipWrapper__YzHkC + .fieldTooltipWrapper__YzHkC {
        margin-top: 18px;
    }

.fieldTooltipWrapper__field__eGzIV {
    flex: 1 1 auto;
}

.fieldTooltipWrapper__tooltip__tN4o7 {
    position: relative;
    flex: 0 0 18px;
    margin-left: 10px;
}

.fieldTooltipWrapper__tooltipIcon__FN4GP {
    height: 18px;
}

.fieldTooltipWrapper__tooltipIcon_padded__U6wIj {
    margin-top: 37px;
}

.infoIcon__8VoXx {
    background-image: url('modules/core/i/info@2x.png#rivcash');
}


.button__AWYWN {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}


.container__addon__elxhd {
    border: none;
}

    .container__addon__elxhd:first-child {
        padding: 0;
    }


.controls_labeled__k2u2K {
    display: flex;
    align-items: center;
}

.rangeSelectWrapper__S5xun {
    flex: 1 1 auto;
}
.control__syEtM {
    display: flex;
    width: 100%;
}
.dash__VSTOw {
  margin: 0 11px;
  display: flex;
  align-items: center;
}


.sizeInputs__t1TPt {
    display: flex;
    align-items: center;
}

.delimiter__D2ARG {
    margin-left: 5px;
    margin-right: 5px;

    flex: 0 0 auto;
}

.input__5KclP {
    flex: 1 1 auto;
    min-width: 0;
}


.inputTypeSwitcher__ChRLr {
    margin-bottom: 18px;

    display: flex;
    align-content: center;
}

.inputTypeSwitcher__buttonLeft__hSExv {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.inputTypeSwitcher__buttonRight__iTZ-1 {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}


.row__MguBb {
    display: flex;
    flex-direction: column;
}
    @media screen and (min-width: 768px) {.row__MguBb {
        flex-direction: row
}
    }

.row__MguBb + .row_withTopMargin__GmVcv {
    margin-top: 18px;
}


.error__-n38Z {
    display: flex;
    align-items: center;
}

@media screen and (min-width: 480px) {

.error__-n38Z {
        padding-bottom: 3px
}
    }

.icon__wnp-H {
    background-image: url('modules/core/globalErrorDialog/components/GlobalErrorDialog/i/error@2x.png#error');
}

.icon__wnp-H + .text__FLHIX {
    margin-left: 20px;
}

@media screen and (min-width: 480px) {

.icon__wnp-H + .text__FLHIX {
        margin-left: 25px
}
    }


.icon_de__fAutv {
    background-image: url('modules/core/lang/components/FlagIcon/i/de@2x.png#language');
}

.icon_en__FfZBL {
    background-image: url('modules/core/lang/components/FlagIcon/i/en@2x.png#language');
}

.icon_es__0aLCX {
    background-image: url('modules/core/lang/components/FlagIcon/i/es@2x.png#language');
}

.icon_fr__swZCB {
    background-image: url('modules/core/lang/components/FlagIcon/i/fr@2x.png#language');
}

.icon_it__nDeT5 {
    background-image: url('modules/core/lang/components/FlagIcon/i/it@2x.png#language');
}

.icon_ru__ACj8Q {
    background-image: url('modules/core/lang/components/FlagIcon/i/ru@2x.png#language');
}


.timeInfo__jqZjF {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    color: #8e8e8e;
}

    @media screen and (min-width: 768px) {.timeInfo__jqZjF {
        flex-direction: row
}
    }

    @media screen and (min-width: 1025px) {.timeInfo__jqZjF {
        flex-direction: column
}
    }

.timeInfo__item__KmZ-7 + .timeInfo__item__KmZ-7 {
        margin-top: 5px;
        margin-left: 0;
    }

@media screen and (min-width: 768px) {

.timeInfo__item__KmZ-7 + .timeInfo__item__KmZ-7 {
            margin-left: 30px;
            margin-top: 0
    }
        }

@media screen and (min-width: 1025px) {

.timeInfo__item__KmZ-7 + .timeInfo__item__KmZ-7 {
            margin-top: 5px;
            margin-left: 0
    }
        }


.alertsBoxWrapper__wfDis {
    padding-bottom: 20px;
}


.flagCell__5IoQc {
    height: 17px;

    display: flex;
    justify-content: center;
    align-items: center;
}


.longWordCell__7VG-J {
    word-break: break-all;
}

    @media screen and (min-width: 1025px) {.longWordCell__7VG-J {
        word-break: normal
}
    }


.numberCell__t4Dw6 {
    text-align: left;
    white-space: nowrap;
}

    @media screen and (min-width: 1025px) {.numberCell__t4Dw6 {
        text-align: right
}
    }




.textCell__Ycx6A {
    padding: 6px 11px;
}

@media screen and (min-width: 1025px) {

.textCell__Ycx6A {
        padding-top: 17px;
        padding-bottom: 17px
}
    }






.withButtonCell__2AXMa {
    display: flex;
    flex-wrap: wrap;

    margin-left: -10px;
    margin-top: -10px;
    padding: 10px 11px;
}

.withButtonCell__item__dySYk {
    display: flex;
    align-items: center;

    margin-left: 10px;
    margin-top: 10px;
}




.status__BGUfy {
    font-family: 'opensans_bold';
}

.status_approved__ZkHHK {
    color: #23bd0b;
}

.status_canceled__vhVkd {
    color: #ff0000;
}

.status_canceledByUser__1GLpS {
    color: #555555;
}

.status_pending__utLUV {
    color: #cd994f;
}


.details__mH14P {
    display: flex;
}

.detailsHeaders__9Wedj {
    flex: 0 0 auto;
    max-width: 175px;
}

.detailsValues__73wJH {
    flex: 1 0 auto;
    margin-left: 33px;

    font-family: 'opensans_bold';
}


.status__GLSI5 {
    font-family: 'opensans_bold';
}

.status_approved__L0p-R {
    color: #23bd0b;
}

.status_canceled__WEiI6 {
    color: #ff0000;
}


.status__4gT-Y {
    font-family: 'opensans_bold';
}

.status_active__DHslk {
    color: #23bd0b;
}

.status_deleted__p62wT {
    color: #ff0000;
}

.status_pending__Wi92c {
    color: #cd994f;
}

.status_suspended__eCF0O {
    color: #ee6315;
}


.body__hUKsY {
    width: 100%;
}
    @media screen and (min-width: 804px) {.body__hUKsY {
        width: 804px
}
    }


.status__IEeH3 {
    font-family: 'opensans_bold';
}

.status_approved__TGUqT {
    color: #23bd0b;
}

.status_deleted__LRJtA {
    color: #555555;
}

.status_pending__H6T4m {
    color: #cd994f;
}

.status_canceled__BRN-c {
    color: #ff0000;
}


.socialSettingsDialog__helpText__r7dUN {
    margin-bottom: 20px;
}

.socialSettingsDialog__buttons__9Hrn3 {
    width: 220px;
    margin: 0 auto;
}


.body__hF4jr {
    width: 767px;
}

    @media screen and (max-width: 767px) {.body__hF4jr {
        width: 100%
}
    }

.photo__G9AVS {
    width: 100%;
}


.body__Q4wnj {
    width: 100%;
}

    @media screen and (min-width: 601px) {.body__Q4wnj {
        width: 600px
}
    }

.content__GbIY8 {
    min-height: 272px;
}

.spinner__bounce__2GRto {
    background-color: #ef007c;
}


.previewSectionWrap__zj69W {
    margin-top: 40px;
}

.previewSectionHeader__UnUQr {
    font-size: 20px;

    margin-bottom: 20px;
}

.previewSectionSubHeader__GD2Tn {
    margin-bottom: 10px;
}

.previewWrap__E5See {
    overflow: auto;
    padding: 5px;
    background: #252525;
    min-width: min(714px, 100%);
    max-width: 100%;
    width: fit-content;
}

.previewButtonWrap__oVvQF {
    margin-top: 10px;
    margin-bottom: 20px;
}

.scriptTextAreaWrap__HUEvu {
    margin-top: 10px;
}

.textArea__zU9To {
    min-height: 110px;
    resize: vertical;
    word-break: break-all;
}




.timeControlButtons__-KMjo {

}

.formFieldsWrapper__HjAUo {
    display: flex;
    margin-top: -18px;

    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-left: 0;
}

.timeControlButtons__-KMjo + .formFieldsWrapper__HjAUo {
    margin-top: 2px;
}

.field__uNgUy {
    margin-left: 0;
    margin-top: 18px;
}

.dateField__MdJPJ {
    width: 100%;
}

.dateRangeEnabled__5UHbE {
    margin-bottom: 8px;
}

.datePickers__g9OVW {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.datePicker__PySXI {
    flex: 1 0 auto;
    width: 100%;
}

.datePicker__PySXI + .datePicker__PySXI {
    margin-top: 10px;
}

.checkbox__8fN9i,
.input__j34RD,
.select__tA-3b {
    max-width: none;
    max-width: initial;
}

.submitButton__shbM3 {
    width: 100%;
}

@media screen and (min-width: 480px) {
    .formFieldsWrapper__HjAUo {
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: wrap;

        margin-left: -44px;
    }

    .field__uNgUy {
        margin-left: 44px;
    }

    .dateField__MdJPJ {
        width: 342px;
    }

    .datePickers__g9OVW {
        flex-direction: row;
    }

    .datePicker__PySXI {
        flex: 1 0 0;
        min-width: 0; /* Note: to make it shrink below width: auto */
        width: auto;
        height: 34px; /* Note: needed for the IE to prevent a bug: IE makes its height = 0px when using flex basis 0 */
    }

    .datePicker__PySXI + .datePicker__PySXI {
        margin-left: 10px;
        margin-top: 0;
    }

    .checkbox__8fN9i,
    .input__j34RD,
    .select__tA-3b {
        max-width: 342px;
    }

    .checkbox__8fN9i {
        margin-bottom: 5px;
    }

    .submitButton__shbM3 {
        width: auto;
    }
}




.affiliateToolSwitcherForm__jbbWa {
    margin-top: 20px;
}


.modelLiveFeedSelector__XOmrN {
}

.modelLiveFeedSelector__row__LwcxM {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 768px) {

.modelLiveFeedSelector__row__LwcxM {
        flex-direction: row
}
    }

.modelLiveFeedSelector__field__w87kK {
    display: flex;
    flex-flow: row nowrap;

    width: 100%;
}

.modelLiveFeedSelector__field__w87kK + .modelLiveFeedSelector__field__w87kK {
        margin-top: 18px;
    }

@media screen and (min-width: 480px) {

.modelLiveFeedSelector__field__w87kK {
        width: 313px
}
    }

@media screen and (min-width: 768px) {
        .modelLiveFeedSelector__field__w87kK + .modelLiveFeedSelector__field__w87kK {
            margin-top: 0;
            margin-left: 88px;
        }
    }

@media screen and (min-width: 480px) {

.modelLiveFeedSelector__field_with_addon__HcDSR {
        width: 340px
}
    }

.input__qWV6x {
    flex: 1 0 auto;
}

.modelLiveFeedSelector__toggle__EHyW2 {
    cursor: pointer;
    margin-left: 11px;
    margin-top: 10px;
}

.modelLiveFeedSelector__toggle_enabled__i-e1h {
    background-image: url('modules/affiliateTools/components/ModelLiveFeedSelector/i/disable@2x.png#modelLiveFeedSelector');
}

.modelLiveFeedSelector__toggle_enabled__i-e1h:hover {
        background-image: url('modules/affiliateTools/components/ModelLiveFeedSelector/i/disableOver@2x.png#modelLiveFeedSelector');
    }
.modelLiveFeedSelector__toggle_disabled__ktGdi {
    background-image: url('modules/affiliateTools/components/ModelLiveFeedSelector/i/enable@2x.png#modelLiveFeedSelector');
}
.modelLiveFeedSelector__toggle_disabled__ktGdi:hover {
        background-image: url('modules/affiliateTools/components/ModelLiveFeedSelector/i/enableOver@2x.png#modelLiveFeedSelector');
    }


.popunderRenderersWrap__SXX54:empty {
    display: none;
}

.iFrameWrap__-XPX6 {
    float: left; /* Note: so it won't take any space */
}




.iFrame__ckSi4 {
    position: fixed;
    bottom: 0;
    right: 0;
}

.iFrame_skype__V2rhn {
    width: 325px;
    height: 115px;
}

.iFrame_facebook__lNw-A {
    width: 350px;
    height: 125px;
}

.iFrame_default__z3Y0D {
    width: 210px;
    height: 120px;
}

.iFrame_gritter__Pjlwe {
    top: 112px;
    bottom: auto;

    width: 321px;
    height: 148px;
}

@media screen and (min-width: 768px) {

.iFrame_gritter__Pjlwe {
        top: 132px
}
    }

.iFrame_livechat__j7ezC {
    width: 350px;
    height: 260px;

    bottom: 10px;
}

.iFrame_nudeincam__RTX5r {
    width: 310px;
    height: 116px;
}


.createBannerButton__I1YTr {
    margin-top: 30px;
}

.label__401uA {
    font-size: inherit;
}


.textLinks__BiWdv {
    margin-top: 40px;
}


.helpText__929-7 {
    margin-bottom: 20px;
}


.balance__4pEE- {
    display: flex;
}

.balance__part__X81Hu + .balance__part__X81Hu {
        margin-left: 44px;
    }


.footer__tWszu {
    background-color: #2a2a2a;
    padding: 33px 11px;
}
    @media screen and (min-width: 768px) {.footer__tWszu {
        padding: 33px 22px 22px
}
    }
    @media screen and (min-width: 1025px) {.footer__tWszu {
        padding: 44px 33px 33px
}
    }

.footer__links__i94Uk {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 768px) {

.footer__links__i94Uk {
        flex-direction: row
}
    }

.footer__linksGroup__XvFMQ {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer__linksGroup__XvFMQ + .footer__linksGroup__XvFMQ {
        margin-top: 33px;
    }

@media screen and (min-width: 768px) {

.footer__linksGroup__XvFMQ {
        align-items: flex-start
}
        .footer__linksGroup__XvFMQ + .footer__linksGroup__XvFMQ {
            margin-top: 0;
            margin-left: 88px;
        }
        .footer__linksGroup__XvFMQ:nth-last-child(2) {
            flex-shrink: 1;
        }
    }

.footer__link__T--rv {
    flex: 0 0 auto;
    color: #929292;
    font: 14px 'opensans_regular';
    text-align: center;
}

.footer__link__T--rv:active,
    .footer__link__T--rv:hover,
    .footer__link__T--rv:focus {
        color: #ef007c;
        text-decoration: none;
    }

@media screen and (min-width: 768px) {

.footer__link__T--rv {
        text-align: left
}
    }

.footer__link__T--rv + .footer__link__T--rv {
        margin-top: 10px;
    }

.footer__copyright__R0Guc {
    font: 14px 'opensans_regular';
    color: rgba(92, 92, 92, 0.5);
    text-align: center;
}

@media screen and (min-width: 768px) {

.footer__copyright__R0Guc {
        text-align: right
}
    }

.footer__links__i94Uk + .footer__copyright__R0Guc {
    margin-top: 33px;
}




.header__tpGV7 {
    height: 112px;
}

@media screen and (min-width: 768px) {

.header__tpGV7 {
        height: 132px
}
    }

.withoutHeader__BZvhf {
    scroll-margin-top: 112px;
    scroll-snap-margin-top: 112px;
}

@media screen and (min-width: 768px) {

.withoutHeader__BZvhf {
        scroll-margin-top: 132px;
        scroll-snap-margin-top: 132px
}
    }

.header__bottomLevel__sSC1T,
.header__topLevel__pPVaB {
    padding-left: 11px;
    padding-right: 11px;
}

@media screen and (min-width: 768px) {

.header__bottomLevel__sSC1T,
.header__topLevel__pPVaB {
        padding-left: 22px;
        padding-right: 22px
}
    }

@media screen and (min-width: 1025px) {

.header__bottomLevel__sSC1T,
.header__topLevel__pPVaB {
        padding-left: 33px;
        padding-right: 33px
}
    }

.header__topLevel__pPVaB {
    display: flex;
    align-items: center;
    height: 68px;
    background: #171717;
}

@media screen and (min-width: 768px) {

.header__topLevel__pPVaB {
        height: 88px
}
    }

.header__logo__gUfxV {
    flex: 0 0 auto;
}

.header__balance__sOV-L {
    flex: 0 0 auto;
    margin-left: auto; /* to align right */
    display: none;
}

@media screen and (min-width: 768px) {

.header__balance__sOV-L {
        display: block
}
    }

.header__webmasterMenu__qV08p {
    flex: 0 0 auto;
    padding-top: 15px;
    margin-right: 11px;
    height: 68px;
}

@media screen and (min-width: 768px) {

.header__webmasterMenu__qV08p {
        height: 88px;
        padding-top: 22px
}
    }

.header__balance__sOV-L + .header__webmasterMenu__qV08p {
    margin-left: auto;
}

@media screen and (min-width: 768px) {

.header__balance__sOV-L + .header__webmasterMenu__qV08p {
        margin-left: 87px
}
    }

.header__bottomLevel__sSC1T {
    height: 44px;
    background-color: #2a2a2a;
}






.layout__EQQlg {
    display: flex;
    height: 100%;
}

.layout__header__UkDQp {
    position: fixed;
    z-index: 10;
    width: 100%;
}

.layout__contentAndFooterWrapper__KdlHu {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 112px;
}

@media screen and (min-width: 768px) {

.layout__contentAndFooterWrapper__KdlHu {
        margin-top: 132px;
}
    }

.layout__contentAndFooterWrapper__KdlHu {
    padding-left: 0;
    transition: padding-left .3s ease;
}
@media screen and (min-width: 1025px) {
.layout__contentAndFooterWrapper_menuOpened__fR-nJ {
        padding-left: 295px
}
    }

.layout__content__bqtg- {
    padding: 12px 11px 22px;
}

@media screen and (min-width: 768px) {

.layout__content__bqtg- {
        padding: 23px 22px 33px;
}
    }

@media screen and (min-width: 1025px) {

.layout__content__bqtg- {
        padding: 34px 33px 44px;
}
    }

.layout__content__bqtg- {

    flex: 1 0 auto;
}

.layout__footer__oN4fn {
    flex: 0 0 auto;
}




.leftMenu__tAlM- {
    position: fixed;
    z-index: 20;
    bottom: 0;
    top: 0;
    padding: 32px 33px;
    width: 295px;
    margin-left: -295px;
    background-color: #202020;
    overflow-y: auto;
    transition: margin-left .3s ease;
}

@media screen and (min-width: 480px) {

.leftMenu__tAlM- {
        top: 112px
}
    }

@media screen and (min-width: 768px) {

.leftMenu__tAlM- {
        top: 132px
}
    }
.leftMenu_opened__aij09 {
    margin-left: 0;
}

.icon_close__XA4SQ {
    background-image: url('modules/app/components/LeftMenu/i/close@2x.png#rivcash');
}
.leftMenu__close__IuJ7J {
    position: absolute;
    top: 11px;
    right: 13px;
}
.leftMenu__close__IuJ7J:hover .icon_close__XA4SQ {
            background-image: url('modules/app/components/LeftMenu/i/closeOver@2x.png#rivcash');
        }

.button__EPhix.leftMenu__howToStart__VWKG1 {
    padding-left: 15px;
    padding-right: 15px;
    width: 196px;
}

.leftMenu__howToStart__VWKG1 + .leftMenu__links__6Z5JA {
    margin-top: 30px;
}

.leftMenu__item__lfR3Y {
}

.leftMenu__link__vDS2L {
    display: table;
    color: #ffffff;
}

.leftMenu__link__vDS2L:active,
    .leftMenu__link__vDS2L:focus,
    .leftMenu__link__vDS2L:hover,
    .leftMenu__link__vDS2L.leftMenu__activeLink__hqBI7 {
        text-decoration: none;
        color: #ef007c;
    }

.leftMenu__link__vDS2L + .leftMenu__link__vDS2L {
    margin-top: 8px;
}

.leftMenu__groupHeader__RABFS + .leftMenu__item__lfR3Y {
    margin-top: 14px;
}

.leftMenu__item__lfR3Y + .leftMenu__groupHeader__RABFS {
    margin-top: 30px;
}




.logo__JKRJl {
    display: flex;
    align-items: center;
}

.logo_RIVcash__UTbNI {
    background-image: url('modules/app/components/Logo/i/logoMobile@2x.png#logo');
}

@media screen and (min-width: 768px) {

.logo_RIVcash__UTbNI {
        background-image: url('modules/app/components/Logo/i/logo@2x.png#logo')
}
    }

.logo_CAMONcash__-uEJK {
    background-image: url('modules/app/components/Logo/i/logoCamonCashMobile@2x.png#logo');
}

@media screen and (min-width: 768px) {

.logo_CAMONcash__-uEJK {
        background-image: url('modules/app/components/Logo/i/logoCamonCash@2x.png#logo')
}
    }

.logo_IXcash__JWaRD {
    background-image: url('modules/app/components/Logo/i/logoIXcashMobile@2x.png#logo');
}

@media screen and (min-width: 768px) {

.logo_IXcash__JWaRD {
        background-image: url('modules/app/components/Logo/i/logoIXcash@2x.png#logo')
}
    }


.container__5FLB0 {
    width: 100%;
    padding: 0 11px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.menu__DYBj- {
    /*width: initial;
    flex-wrap: wrap-reverse;*/
}

.menu_strip__FYLVX {
    flex-grow: 1;
}


.menu_more__KY12T {
    flex-shrink: 0;
}


.menu_more__KY12T .menuItem__p6E7d {
        margin-right: 0;
    }

.stripMenuItem__S-uBy:first-child {
        margin-left: 0;
    }

.stripMenuItem__S-uBy:last-child {
        margin-right: 0;
        margin-left: auto;
        padding-left: 15px;
    }
.stripMenuItem_hidden__5lbYr {
    visibility: hidden;
}
.stripMenuItem__S-uBy .stripMenuItemLink__j6kb1 {
    white-space: normal;
    word-break: break-all;
}

.icon_en__esIxd {
    border-radius: 2px;
    background-image: url('modules/app/components/Menu/i/en@2x.png#rivcash');
}
.icon_it__QM1Uq {
    border-radius: 2px;
    background-image: url('modules/app/components/Menu/i/it@2x.png#rivcash');
}

.icon_arrow__2tmiJ {
    background-image: url('modules/core/i/arrowWhite@2x.png#rivcash');
    margin-left: 11px;
}
.menuItem_open__s2Nfy .icon_arrow__2tmiJ {
    background-image: url('modules/core/i/arrowPink@2x.png#rivcash');
}

.icon_hamburger__V7y-l {
    background-image: url('modules/app/components/Menu/i/hamburger@2x.png#rivcash');
}
.icon_openHamburger__C-aKL,
.menuItem_open__s2Nfy .icon_hamburger__V7y-l {
    background-image: url('modules/app/components/Menu/i/hamburgerOver@2x.png#rivcash');
}

.submenu_align_right__P9oaz {
    right: -11px;
}

.submenuItemLanguage__W8msE {
    display: block;
}

.submenuItemLinkLanguage__07b3P {
    margin-right: 12px;
    justify-content: space-between;
}

.submenuItemLinkLanguage__07b3P:after {
        content: '';
        display: block;
    }

.submenuItemLinkLanguage_en__HZSP9:after {
        border-radius: 2px;
        background-image: url('modules/app/components/Menu/i/en@2x.png#rivcash');
    }

.submenuItemLinkLanguage_it__id23c:after {
        border-radius: 2px;
        background-image: url('modules/app/components/Menu/i/it@2x.png#rivcash');
    }


.alert__P00t9 {
    flex-grow: 1;
}

.message__Hudy2 {
    position: relative;
    padding-right: 35px;
}

.close__VQkcV {
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;
    background-image: url('modules/app/components/Notifications/i/close@2x.png#rivcash');
    opacity: .5;
}

.close__VQkcV:hover {
        opacity: 0.75;
    }


.webmasterMenu__tXJFw {

}

.webmasterMenu__account__7eXFl {
    font: 12px 'opensans_regular';
    text-transform: uppercase;
    color: #8e8e8e;
    display: none;
    height: 18px;
}

@media screen and (min-width: 768px) {

.webmasterMenu__account__7eXFl {
        display: block
}
    }

.webmasterMenu__menu__Oq5lr {
    height: 53px;
}

@media screen and (min-width: 768px) {

.webmasterMenu__menu__Oq5lr {
        height: 48px
}
    }

.webmasterMenu__menu__Oq5lr {

    position: relative;
    z-index: 2;
}

.webmasterMenu__menu__Oq5lr .webmasterMenu__menuItem__KSdhf {
        height: 45px;
        padding-bottom: 11px;
        margin: 0 0 8px;
    }

@media screen and (min-width: 768px) {

.webmasterMenu__menu__Oq5lr .webmasterMenu__menuItem__KSdhf {
            height: 32px;
            padding-bottom: 8px;
            margin-bottom: 16px
    }
        }

.webmasterMenu__menu__Oq5lr .icon_arrow__gPQ-6 {
        background-image: url('modules/core/i/arrowWhite@2x.png#rivcash');
        margin-left: 11px;
    }

.webmasterMenu__menu__Oq5lr .link_open__CHEv5 .icon_arrow__gPQ-6 {
        background-image: url('modules/core/i/arrowPink@2x.png#rivcash');
    }

.webmasterMenu__menu__Oq5lr .menuItemLink__R-2nb.actionLink__6ZtQx .link__rvsMl {
        color: #ffffff;
    }

.webmasterMenu__menu__Oq5lr .menuItemLink__R-2nb.actionLink__6ZtQx .link_open__CHEv5 {
        color: #ef007c;
    }

.webmasterMenu__menu__Oq5lr .webmasterMenu__submenu__gQpNW {
        padding-top: 16px;
        padding-bottom: 16px;
        top: 44px;
        right: -11px;
    }

@media screen and (min-width: 768px) {

.webmasterMenu__menu__Oq5lr .webmasterMenu__submenu__gQpNW {
            top: 32px
    }
        }

.webmasterMenu__menu__Oq5lr .webmasterMenu__submenuItem_balancePart__LAh0T {
        height: 40px;
        padding-left: 23px;
        margin-top: 15px;
        margin-bottom: 5px;
        display: block;
    }

@media screen and (min-width: 768px) {

.webmasterMenu__menu__Oq5lr .webmasterMenu__submenuItem_balancePart__LAh0T {
            display: none
    }
        }

.webmasterMenu__menu__Oq5lr .webmasterMenu__submenuItemLink__hfxrP {
        padding: 0 22px;
        text-decoration: none;
    }

.webmasterMenu__menu__Oq5lr .webmasterMenu__submenuItemLink__hfxrP:active,
        .webmasterMenu__menu__Oq5lr .webmasterMenu__submenuItemLink__hfxrP:focus,
        .webmasterMenu__menu__Oq5lr .webmasterMenu__submenuItemLink__hfxrP:hover {
            color: #ef007c;
        }

.webmasterMenu__menu__Oq5lr .webmasterMenu__submenuItemLink_active__4PbEI {
        color: #ef007c;
    }

.webmasterMenu__balanceTotal__IoaDj {
    font: 16px/1 'opensans_regular';
    padding-top: 5px;
    padding-bottom: 2px;
    text-align: left;
}

@media screen and (min-width: 768px) {

.webmasterMenu__balanceTotal__IoaDj {
        display: none
}
    }
.webmasterMenu__balanceTotal_negative__s56fg {
    color: #ff0000;
}
.link_open__CHEv5 .webmasterMenu__balanceTotal__IoaDj {
    color: #ef007c;
}


.help__IoUXC {
    margin-top: 20px;
}

.details__688XG {
    margin-top: 20px;
}

.form__0Prqt {
    margin-top: 20px;
}


.image__uyBqJ {
    max-width: 100%;
    margin-top: 10px;
    max-height: 390px;
}


.banner__header__QCev7 {
    display: flex;
    align-items: center;
}

.banner__previewWrap__UpPb4 {
    overflow: auto;
    margin-top: 20px;
}

.banner__buttonsWrap__7Drm- {
    margin-top: 10px;
}

.banner__buttonsContainer__IasSD {
    justify-content: flex-start;
}

.banner__buttonsContainer__button__1zTmW:last-child {
    display: flex;
    align-items: center;
}

@media screen and (min-width: 480px) {

.banner__buttonsContainer__button__1zTmW:last-child {
        margin-left: 22px
}
    }


.status__oyghH {
    font-family: 'opensans_bold';
}

.status_approved__XdkAq {
    color: #23bd0b;
}

.status_canceled__sD5rU {
    color: #ff0000;
}

.status_pending__px4u1 {
    color: #cd994f;
}


.bannerWrap__XJweQ + .bannerWrap__XJweQ {
    margin-top: 30px;
}
    @media screen and (min-width: 480px) {.bannerWrap__XJweQ + .bannerWrap__XJweQ {
        margin-top: 40px
}
    }


.helpText__CMG5o {
    margin-bottom: 20px;
}


.label__c0jFZ {
    font-family: 'opensans_regular', sans-serif;
    font-size: 20px;
    margin-left: 12px;
    cursor: pointer;
}
.label_disabled__qYRW9 {
    cursor: default;
}

.exchangeBannerCheckbox__cGH4p {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.spinnerWrapper__CpOhF {
    width: 20px;
}


.helpText__5FC7t {
    margin-bottom: 20px;
}

.programHelpText__YBQ8S {
    margin-top: 20px;
}


.programNameItem__sUO8o {
    vertical-align: bottom;
}

.programNameItem__sUO8o + .programNameItem__sUO8o {
    margin-left: 5px;
}

.infoIcon__Eqhj1 {
    display: inline-block;
    background-image: url('modules/core/i/info@2x.png#rivcash');
}


.gridWrapper__09IJq {
    overflow: auto;
    margin-top: 20px;
}


.certificateStatusWrapper__VDlr1 {
    width: 18px;
    display: inline-block;
    margin-right: 7px;
    vertical-align: middle;
}

.certificateStatus__haLs3 {
    cursor: help;
}

.certificateStatus_pending__cIc-P {
    background-image: url('modules/certificate/components/CertificateStatus/i/domainPending@2x.png#certificate');
}

.certificateStatus_secure__F3-xM {
    background-image: url('modules/certificate/components/CertificateStatus/i/domainSecure@2x.png#certificate');
}

.certificateStatus_insecure__GiWNz {
    cursor: pointer;
    background-image: url('modules/certificate/components/CertificateStatus/i/domainInsecure@2x.png#certificate');
}

.certificateStatus_insecure__GiWNz:hover {
        background-image: url('modules/certificate/components/CertificateStatus/i/domainInsecureOver@2x.png#certificate');
    }




.dragArea__QFS-b {
    position: relative;
    border: 2px dashed #454545;
    text-align: center;
    padding: 20px;
}

.dragAreaHighlighted__dPs1r {
    transition: .3s ease;

    border-color: #5c5c5c;
}

.dragAreaHovered__Obufp {
    border-color: #b2b2b2;
}

.dropAreaLabel__6qq7j {
     font-size: 20px;
}

.dropAreaSubLabel__6Njri {
    margin: 5px 0;
    font-size: 14px;
}

.fileInput__xo6Y2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}


.headerWithInfo__mieXE {

}

.headerWithInfo__icon__Z1TjK {
    margin-left: 22px;
    display: inline-block;
    cursor: pointer;
}

.infoIcon__G5EN3 {
    background-image: url('modules/core/i/info@2x.png#rivcash');
}
.infoIcon_over__VSA6N {
    background-image: url('modules/core/i/infoOver@2x.png#rivcash');
}

.headerWithInfo__infoContentWrapper__nAlhI {
    overflow: hidden;
    max-height: 0;
    transition: max-height .3s ease;
}

.headerWithInfo__infoContent__-DdqY {
    width: 100%;
    font: 14px 'opensans_regular';
    padding-top: 10px;
}

@media screen and (min-width: 768px) {

.headerWithInfo__infoContent__-DdqY {
        padding-top: 15px
}
    }

@media screen and (min-width: 1025px) {

.headerWithInfo__infoContent__-DdqY {
        padding-top: 20px
}
    }


.htmlFromLangKey__D15Cw {
    font-family: opensans_regular;
    font-size: 14px;
}

    .htmlFromLangKey__D15Cw p,
    .htmlFromLangKey__D15Cw ul {
        margin: 0;
    }

    .htmlFromLangKey__D15Cw p + p,
    .htmlFromLangKey__D15Cw ul + ul,
    .htmlFromLangKey__D15Cw p + ul,
    .htmlFromLangKey__D15Cw ul + p {
        margin: 10px 0 0;
    }

    .htmlFromLangKey__D15Cw a {
        font: 14px 'opensans_regular', sans-serif;
        color: #ef007c;
        text-decoration: none;
    }

    .htmlFromLangKey__D15Cw a:active,
        .htmlFromLangKey__D15Cw a:focus,
        .htmlFromLangKey__D15Cw a:hover {
            text-decoration: underline;
        }


.iFrame__NULt1 {
  display: block;
}


.page__zqSV- {

}

.page__header__IhZp4 {
    margin-bottom: 20px;
}
.page__content__zMR-f {
    font: 14px 'opensans_regular';
}


.tox-tinymce {
        border-color: #454545 !important;
        border-radius: 17px !important;
        border-width: 1px !important;
    }


.buttons__SBHUN {
    display: flex;
    margin-top: -11px;
    margin-left: -11px;

    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (min-width: 480px) {

.buttons__SBHUN {
        justify-content: flex-start
}
    }

.button__ADI6P {
    margin-left: 11px;
    margin-top: 11px;
}


.link__f0STY {
}

.link_summary__-MOxw {
}

.link__text__asm48 {
    font-size: 14px;
}


.gridWrapper__qZV3N {
    margin-top: 30px;

    overflow: visible;
}


.tableGrid__q5n-G {
    display: inline-block; /* to keep paging by the right border of a grid */
    max-width: 100%; /* to allow a horizontal scrolling for a child */
}

.gridContainer__m-FQk {
    display: inline-block; /* to keep a grid from being unnecessarily wide */
    max-width: 100%; /* needed by the VertTable widthMeter */
    overflow: auto;
}

.paginationContainer__y-M0i + .gridContainer__m-FQk,
.gridContainer__m-FQk + .paginationContainer__y-M0i {
    margin-top: 20px;
}

.grid__n3EbQ {
}


.gridWrapper__UeBBO {
    overflow: auto;
    margin-top: 20px;
}


@media screen and (min-width: 768px) {

    }
    @media screen and (min-width: 1025px) {

    }

.payoutSettingsPage__buttons__Eygkr {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
}

.payoutSettingsPage__alertsBox__kf91F {
    margin: 20px 0;
}

.payoutSettingsPage__options__gsRN5 {
    margin-top: 20px;
}

.payoutSettingsPage__option__Sqi68 {
    margin-top: 30px;
}

.payoutSettingsPage__optionName__pvR6y {
    font-weight: bold;
}

.payoutSettingsPage__optionContent__hWXK3 {
    margin-top: 10px;
}

.payoutSettingsPage__payoutRequest__chsWK {
    margin-top: 22px;
}

.button__isWVP.payoutSettingsPage__payoutRequestButton__qPcb2 {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

@media screen and (min-width: 480px) {

.button__isWVP.payoutSettingsPage__payoutRequestButton__qPcb2 {
        width: 206px
}
    }


.textArea__2Pi5q {
    min-height: 200px;
}


.helpText__WxFed {
    margin-bottom: 20px;
}

.postbackSettingsPage__test__OPKDq {
    margin: 20px 0;
}


.filterForm__5IJLn {

}

.gridWrapper__h48L8 {
    overflow: auto;
    margin-top: 20px;
}


.icon_content__kx2h4 {
    background-image: url('modules/site/components/SiteMenu/i/content@2x.png#siteMenu');
}
.icon_settings__ePxWq {
    background-image: url('modules/site/components/SiteMenu/i/settings@2x.png#siteMenu');
}
.icon_links__EhLzs {
    background-image: url('modules/site/components/SiteMenu/i/links@2x.png#siteMenu');
}
.icon_seo__MN-l1 {
    background-image: url('modules/site/components/SiteMenu/i/seo@2x.png#siteMenu');
}

.menu__SqFKI {
    z-index: 1;
    height: auto;
    border-bottom: 1px solid #343434;
}

.menu__SqFKI:after {
        content: "";
        display: block;
        clear: both;
    }

.menuItem__PhUVw {
    height: 40px;
}

.menuItem__PhUVw:first-child {
        margin-left: 0;
    }

.menuItemLink__V1y1u {
    position: relative;
    z-index: 1;
}

.menuItemLink__V1y1u.link__m1eZy {
        font: 14px opensans_regular, sans-serif;
    }

.menuItemLink__V1y1u.link_open__e6M8J,
        .menuItemLink__V1y1u.link_open__e6M8J:active,
        .menuItemLink__V1y1u.link_open__e6M8J:focus,
        .menuItemLink__V1y1u.link_open__e6M8J:hover,
        .menuItemLink__V1y1u.link_active__QnirE,
        .menuItemLink__V1y1u.link_active__QnirE:active,
        .menuItemLink__V1y1u.link_active__QnirE:focus,
        .menuItemLink__V1y1u.link_active__QnirE:hover {
            color: #ef007c;
        }

.menuItemLink__V1y1u.link_open__e6M8J .icon_content__kx2h4, .menuItemLink__V1y1u.link_active__QnirE .icon_content__kx2h4 {
            background-image: url('modules/site/components/SiteMenu/i/contentOver@2x.png#siteMenu');
        }

.menuItemLink__V1y1u.link_open__e6M8J .icon_settings__ePxWq, .menuItemLink__V1y1u.link_active__QnirE .icon_settings__ePxWq {
            background-image: url('modules/site/components/SiteMenu/i/settingsOver@2x.png#siteMenu');
        }

.menuItemLink__V1y1u.link_open__e6M8J .icon_links__EhLzs, .menuItemLink__V1y1u.link_active__QnirE .icon_links__EhLzs {
            background-image: url('modules/site/components/SiteMenu/i/linksOver@2x.png#siteMenu');
        }

.menuItemLink__V1y1u.link_open__e6M8J .icon_seo__MN-l1, .menuItemLink__V1y1u.link_active__QnirE .icon_seo__MN-l1 {
            background-image: url('modules/site/components/SiteMenu/i/seoOver@2x.png#siteMenu');
        }

.menuItemLink__V1y1u .link__text__Z0XTO {
        display: none;
    }

@media screen and (min-width: 480px) {

.menuItemLink__V1y1u .link__text__Z0XTO {
            display: block
    }
        }

/* arrow */
@media screen and (min-width: 480px) {
        .link_active__QnirE::before, .link_open__e6M8J::before {
            display: block;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: #171717;
            z-index: 1;
        }
        .link_active__QnirE .link__text__Z0XTO,
        .link_active__QnirE .link__icon__G8u-n,
        .link_open__e6M8J .link__text__Z0XTO,
        .link_open__e6M8J .link__icon__G8u-n {
            z-index: 1;
        }
        .link_active__QnirE:after, .link_open__e6M8J:after {
            display: block;
            content: "";
            position: absolute;
            bottom: -7px;
            left: 50%;
            width: 14px;
            height: 14px;
            background-color: #171717;
            border: 1px solid #343434;
            z-index: -1;
            transform: translate(6px,0) rotate(45deg);
        }
}

.submenu__3mWuF {
    padding: 15px 0;
}
.submenuItemLink__U4hM1 {
    padding: 0 25px;
}


.siteMenu__Xtps6 {
    margin-top: 25px;
}

.sitePageContent__Kze9E {
    padding-top: 30px;
    width: 100%;
}


.createSiteAliasButton__793PI {
    margin-top: 10px;
    margin-bottom: 40px;
    width: 100%;
}

    @media screen and (min-width: 480px) {.createSiteAliasButton__793PI {
        width: 206px
}
    }


.createSiteButton__5DmEl {

}
.createSiteButton__button__RARrl {
    margin-top: 10px;
    margin-bottom: 40px;
    width: 100%;
}
@media screen and (min-width: 480px) {
.createSiteButton__button__RARrl {
        width: 206px
}
    }
.createSiteButton__text__3CGqj {
    margin-top: 30px;
    margin-bottom: 20px;
}


.siteForm__MA-oW {

}
.siteForm__subheader__7k-9t {
    padding: 17px 0 15px 0;
}


.templatePreview__79qW- {
    margin-top: 4px;
    max-width: 100%;
    max-height: 475px;
    border: 1px solid #454545;
}


.textArea__0vCSq {
    min-height: 200px;
}


.status__A5Ar- {
    font-family: 'opensans_bold';
}

.status_approved__Vm2MD {
    color: #23bd0b;
}

.status_declined__1HPr3 {
    color: #ff0000;
}

.status_pending__KIDmS {
    color: #cd994f;
}


.favicon__wrapper__GTYbi {
    display: flex;
    justify-content: center;
    margin-top: 11px;
}

.faviconButton__Hgbd6 {
    margin-top: 11px;
    text-align: center;
}


.textArea__np6A1 {
    min-height: 200px;
}


.helpText__6yrfm {
    margin-bottom: 20px;
}

.statusInfo__-IifZ {
    font-family: 'opensans_regular', sans-serif;
    font-size: 14px;
    margin: 0 0 15px 0;
}

.status__iDVDv {
    font-family: 'opensans_bold', sans-serif;
}
.status_pending__WYZT8 {
    color: #cd994f;
}
.status_approved__-9Aer {
    color: #23bd0b;
}
.status_canceled__p3jeY {
    color: #ff0000;
}


.pageGrid__UVlr6 {
}

.spanCell__op5h8 {
}

.spanCell__buttons__zNGjk {
    margin-left: -10px;

    display: flex;
    flex-wrap: wrap;
}

@media screen and (min-width: 1025px) {

.spanCell__buttons__zNGjk {
        flex-direction: column
}
    }

.spanCell__button__qLjRK {
    margin-top: 10px;
    margin-left: 10px;
}

.availabilityToggleCell__d9CQV {
    display: flex;
    justify-content: center;
}

.status_pending__WNhO- {
    color: #cd994f;
}


.status__AD-N5 {
    font-family: 'opensans_bold';
    font-size: 14px;
}

.status_approved__VeVEG {
    color: #23bd0b;
}
.status_pending__zqc5Y {
    color: #cd994f;
}
.status_canceled__LUQ1z {
    color: #ff0000;
}
.status_deleted__FOfpV {
    color: #555555;
}


.status__iqQxI,
.lang__-4X-j {
    margin-left: 10px;
}
.translationLabel__c01JX {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.categoryInfo__BPj1p + .categoryInfo__BPj1p {
          margin-top: 11px;
    }
.statusAndLang__7rOfX {
    display: flex;
    align-items: center;
}
.moderateStatus__2KN7x {
    margin-left: 10px;
}


.createButtonText__RODhJ {
    margin-top: 30px;
    margin-bottom: 20px;
}


.grids__3AW6i {
    margin-top: 20px;
}

.goToSeoLink__1LN5e {
    margin-top: 15px;
}

.goToSeoLinkText__xMRoJ {
    font-size: 14px;
}


.fileContent__gK0kH {
    text-align: center;
    max-width: 320px;
    width: 100%;
    margin-top: 11px;
}

.helpText__cRl5e {
    margin-bottom: 20px;
}

.actions__ERm4v {
    display: flex;
    flex-direction: row;
    margin-top: 11px;
}

.action__L48ai + .action__L48ai {
        margin-left: 10px;
    }


.buttonsContainer__wK89f {
    justify-content: flex-start;
}


.pageTextHelp__DFNxr {
    margin-bottom: 20px;
}


.customImage__ESHaZ {
    text-align: center;
}

    .customImage__ESHaZ + .customImage__ESHaZ {
        margin-top: 25px;
    }
.customImage__header__e0zc5 {
    margin-bottom: 11px;
    text-align: left;
}
.customImage__button__IZ25J {
    text-align: center;
    margin-top: 11px;
}
.customImage__wrapper__PGRhb {
    margin-top: 11px;
    display: flex;
    justify-content: center;
}
.customImage__preview__drJFo {
    display: block;
    max-width: 313px;
    max-height: 133px;
    background: url('/static/images/bg_images/logo_bg.png') repeat;
}


.templateConfigurationPage__block__R0VTb + .templateConfigurationPage__block__R0VTb {
       margin-top: 35px;
    }
    .templateConfigurationPage__block__R0VTb + .templateConfigurationPage__block__R0VTb.templateConfigurationPage__block_inner__4Aiq1 {
        margin-top: 25px;
    }
    @media screen and (max-width: 767px) {
        .templateConfigurationPage__block__R0VTb .templateConfigurationPage__row__UBwKt {
            flex-direction: column-reverse;
        }
            .templateConfigurationPage__block__R0VTb .templateConfigurationPage__column__SJZh8 + .templateConfigurationPage__column__SJZh8 {
                margin-top: 0px;
                margin-bottom: 20px;
            }
            .templateConfigurationPage__block__R0VTb .templateConfigurationPage__column_group__gg4jZ + .templateConfigurationPage__column_group__gg4jZ {
                margin-top: 25px;
            }
    }

.templateConfigurationPage__subheader__Xq65L {
    margin-bottom: 15px;
}


/*TOOD nana remove copy-past*/
.translationField__ULpZ7 + .translationField__ULpZ7 {
        margin-top: 10px;
    }

.status__odRnq,
.seoType__DM7Cq {
    margin-left: 10px;
}
.translationLabel__chmaO {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.textarea__yMiVL {
}

.saveButton__Ic5WE {
    margin-top: 10px;
}


@media screen and (min-width: 480px) {.simpleField__a60jY {
        max-width: 390px
}
    }


.pageGrid__szcWy {
}

.spanCell__R7Bz0 {
}

.spanCell__buttons__wA0sq {
    margin-left: -10px;

    display: flex;
    flex-wrap: wrap;
}

@media screen and (min-width: 1025px) {

.spanCell__buttons__wA0sq {
        flex-direction: column
}
    }

.spanCell__button__SjzrJ {
    margin-top: 10px;
    margin-left: 10px;
}

.availabilityToggleCell__raaOQ {
    display: flex;
    justify-content: center;
}

.status__j8uY8 {
    font-family: opensans_bold;
}

.status_approved__6baIY {
    color: #23bd0b;
}

.status_canceled__ijJxT {
    color: #ff0000;
}

.status_deleted__laIQ- {
    color: #555555;
}

.status_pending__6ojoQ {
    color: #cd994f;
}


.grids__rQAcK {
    margin-top: 20px;
}

.goToSeoLink__ABZgy {
    margin-top: 15px;
}

.goToSeoLinkText__bTgpn {
    font-size: 14px;
}


.translationField__TSLlu + .translationField__TSLlu {
        margin-top: 10px;
    }

.status__suS44 {
    margin-left: 10px;
}

.textarea__A-pTN {
}

.saveButton__HQnUO {
    margin-top: 10px;
}


.tag__UmJft {
    margin-top: 20px;
}


.pageLink__8VyHR {
    font-size: 14px;
}

.translationCells__wAmPx {
}

.translationCell__-SNi5 + .translationCell__-SNi5 {
        margin-top: 30px;
    }

.translationCell__title__oKHmJ {
    font-family: 'opensans_bold';
}

.translationCell__content__cnczW {
    margin-top: 14px;
}

.translationCell__content__cnczW:empty {
        display: none;
        margin-top: 0;
    }

.translationCell__buttons__xhAYv {
    margin-top: 7px;
    margin-left: -10px;

    display: flex;
    flex-wrap: wrap;
}

.translationCell__button__j8d-s {
    margin-top: 10px;
    margin-left: 10px;
}


.status__2KTtm {
    margin-left: 10px;
}

.translation__pQY69 + .translation__pQY69 {
        margin-top: 11px;
    }


.status__EJ7VX {
    font-family: 'opensans_bold';
    font-size: 14px;
}

.status_approved__KTtFG {
    color: #23bd0b;
}

.status_pending__9yL3r {
    color: #cd994f;
}
.status_canceled__gW7f- {
    color: #ff0000;
}


.status__aLp9c {
    font-family: 'opensans_bold';
    font-size: 14px;
}

.status_active__WkI9I {
    color: #23bd0b;
}

.status_pending__kAfEy {
    color: #cd994f;
}
.status_canceled__6iNAJ {
    color: #ff0000;
}


.flagCell__277HU {
    display: flex;
    justify-content: center;
}


.pageTextHelp__cS4N6 {
    margin-bottom: 15px;
}
.gridWrapper__bjvdJ {
    margin-top: 25px;
}
.gridWrapper__bjvdJ + .gridWrapper__bjvdJ{
    margin-top: 30px;
}
.section__DYlTS {
    margin-top: 30px;
}

.siteTextsLangFilter__z3zyn {
    display: flex;
}
.siteTextsLangFilter__label__KW0EQ {
    margin-right: 12px;
}
.siteTextsLangFilter__options__ZqCqu {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -12px;
    margin-right: -12px;
}
.siteTextsLangFilter__option__Nx1y2 {
    display: inline-flex;
    margin-right: 12px;
    margin-bottom: 12px;
}


.translationWithStatusCell__E6c1y {

}
.translationWithStatusCell__text__o9Grp {

}
.translationWithStatusCell__status__t51Xl {
    margin-top: 5px;
}


.button_google__1pcgX {
    background: #4285F4;
}

.button_google__1pcgX:hover,
    .button_google__1pcgX:focus  {
        background: #3D7AE0;
    }

.button_twitter__DstQb {
    background: #55ACEE;
}

.button_twitter__DstQb:hover,
    .button_twitter__DstQb:focus  {
        background: #2795E9;
    }

.button__PvP8C {
    height: 40px;
    padding: 2px;
    border-radius: 5px;
    width: 100%;
}

.button_spinner__87X3q .button__inner__sBFxv {
        justify-content: center;
    }

.button__inner__sBFxv {
    justify-content: initial;
}

.button__text__xJR9R {
    flex-grow: 1;
}

.button__icon__cNvj6 + .button__text__xJR9R,
.button__text__xJR9R + .button__icon__cNvj6 {
    margin-left: 0;
}

.button__icon_google__Cpp-d {
    background-image: url('modules/socialNetworksSettings/components/SocialButton/i/google@2x.png#social');
}

.button__icon_twitter__8TpFG {
    background-image: url('modules/socialNetworksSettings/components/SocialButton/i/twitter@2x.png#social');
    margin: 0 8px 0 12px;
}


.buttonsContainer__StYfE {

}

.buttonsContainer__button__p8IEJ + .buttonsContainer__button__p8IEJ {
        margin-top: 20px;
    }

@media screen and (min-width: 480px) {
        .buttonsContainer__button__p8IEJ + .buttonsContainer__button__p8IEJ {
            margin-top: 11px;
        }
    }


.buttonWrapper__twi7R {
    margin-top: 20px;
}


.swiftColumn__bVW0a {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}


.helpText__0rUNl {
    margin-bottom: 20px;
}

.programHelpText__5E9N2 {
    margin-top: 20px;
}


.activeLabel__CAxI9 {
    margin-bottom: 10px;
    color: #40a112;
}

