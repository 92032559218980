.footer {
    display: flex;
    flex-direction: column;
    margin: 10px 0 15px;
    width: 100%;

    @media screen and (width > $mobileBreakPoint) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 22px 0;
    }
}

.footer__link {
    text-align: center;
}

.footer__button {
    width: 100%;
    margin-top: 15px;

    @media screen and (width > $mobileBreakPoint) {
        margin-top: 0;
        width: auto;
    }
}

.rememberMe {
    display: flex;
    justify-content: center;
}

.rememberMe__inner {
    @media screen and (width > $mobileBreakPoint) {
        width: 100%;
    }
}
