:global {
    .newsFull {

    }
    .faqItem + .faqItem,
    .newsFull + .newsFull {
        margin-top: 25px;
    }
    .faqItem__question,
    .newsFull__header {
        color: #ef007c;
        font: 14px opensans_bold, sans-serif;
        margin-bottom: 13px;
    }
    .faqItem__answer,
    .newsFull__text {
        line-height: 20px;
    }
    .htmlText .faqPage,
    .htmlText .newsFull,
    .htmlText .newsItem {
        p + p {
            margin-top: 13px;
        }
    }
}
