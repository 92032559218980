@font-face {
    font-family: 'opensans_bold';
    src: url('../fonts/OpenSans/OpenSans-Bold-webfont.eot');
    src: url('../fonts/OpenSans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans/OpenSans-Bold-webfont.woff') format('woff'),
    url('../fonts/OpenSans/OpenSans-Bold-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans_regular';
    src: url('../fonts/OpenSans/OpenSans-Regular-webfont.eot');
    src: url('../fonts/OpenSans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans/OpenSans-Regular-webfont.woff') format('woff'),
    url('../fonts/OpenSans/OpenSans-Regular-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'opensans_light';
    src: url('../fonts/OpenSans/OpenSans-Light-webfont.eot');
    src: url('../fonts/OpenSans/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans/OpenSans-Light-webfont.woff') format('woff'),
    url('../fonts/OpenSans/OpenSans-Light-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans/OpenSans-Light-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

