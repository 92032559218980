@value imagePath: landing/i;

.mobileMenuModalDialog {
    .body {
        width: 320px;
    }
}
.mobileMenuModalDialog__menuItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 15px;
    @media screen and (width > $mobileBreakPoint) {
        margin-top: 0;
    }
}
.mobileMenuModalDialog__menuItem {
    width: 100%;
    padding-left: 22px;
}
.mobileMenuModalDialog__menuItem {
    & + & {
        margin-top: 23px;
    }
}
.mobileMenuModalDialog__link {
    color: #ffffff;
    font-size: 16px;

    &:hover {
        color: #ef007c;
        text-decoration: none;
    }
}

.mobileMenuModalDialog__icon_en {
    background-image: url('imagePath/en@2x.png#landing');
}
.mobileMenuModalDialog__icon_it {
    background-image: url('imagePath/it@2x.png#landing');
}
.mobileMenuModalDialog__button {
    width: 100%;
    margin: 30px 0;

}
.mobileMenuModalDialog__menuItem_loginItem {
    display: block;
    @media screen and (width > $mobileBreakPoint) {
        display: none;
    }
}
.mobileMenuModalDialog__menuItem_langItem {
    display: block;
    @media screen and (width > $smallTabletBreakPoint) {
        display: none;
    }
}
